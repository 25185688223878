import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import auth from "../../auth";
import { messages } from "../utils/constants";
import { useHistory } from "react-router-dom";
import { notify } from '../utils/utility'


import "../../style/global.css";
// import "../../style/reset-password.css";
import Logo from "../../images/logo.png";
import Gadgets from "../../images/gadgets.png";
import { useInputHook } from "../custom/custom-input-hook";
import SimpleCrypto from "simple-crypto-js";

const simpleCrypto = new SimpleCrypto(messages.scSecretKey);


const SetNewPassword = () => {
  const [password, setPassword] = useInputHook();
  const [confirmPassword, setConfirmPassword] = useInputHook();
  const [passwordErr, setPasswordErr] = useState({});
  const [confirmPasswordErr, setConfirmPasswordErr] = useState({});
  // const [spinner, setSpinner] = useState(false);



  const history = useHistory();


  var token = window.location.href.split("/");
  const myData = simpleCrypto.decrypt(token[4].replace(/hsals/g, '\/'))
  const myData1 = myData.split("|")
  const userId = myData1[0];


  useEffect(() => {
    if (token) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      token = token[4];
      axios.post('/verifyPasswordToken', { token })
        .then(function (response) {
          // handle success
          let data = response.data ? response.data : {};
          if (!data["errorCode"]) {
            return true;
          } else if (data.errorCode === messages.NotFound) {
            notify(messages.ToastError, messages.ExpiryLink);
            history.push("/");
          } else if (data.errorCode === messages.LinkExpired) {
            notify(messages.ToastError, messages.ExpiryLink);
            history.push("/");
          }
          //hideLoader();
        })
        .catch(function (error) {
        })
    }
  }, [userId]);


  var data = { password, confirmPassword, userId };
  const submitHandler = (e) => {
    e.preventDefault(e);

    const isValid = formValidation();
    if (isValid) {
      // setSpinner(true);
      setTimeout(function () {
        // setSpinner(false);
      }, 2000);
      axios
        .post("/changePassword", data)
        .then((res) => {
          // setSpinner(false);
          if (res.data.errorCode) {
            notify(messages.ToastError, messages.ResetPasswordFail);
          } else {
            notify(messages.ToastSuccess, messages.ResetPasswordSuccessfully);
            // setSpinner(false);
            setTimeout(() => {
              auth.Login(() => {
                history.push("/");
              });
            }, 1000);
          }
        })
        .catch((error) => {
          notify(messages.ToastError, messages.ResetPasswordFail);
          // setSpinner(false);
        });
    }
  };
  const formValidation = () => {
    const confirmPasswordErr = {};
    const passwordErr = {};
    let isValid = true;
    if (password !== confirmPassword) {
      confirmPasswordErr.confirtPasswordNotMatch = "password does not match";
      isValid = false;
    }
    if ((password === undefined) || (password.trim().length < 6)) {
      passwordErr.passwordShort = "password must be minimum 6 character long";
      isValid = false;
    }
    setConfirmPasswordErr(confirmPasswordErr);
    setPasswordErr(passwordErr);
    return isValid;
  };

  return (
    <Row className="main-root">
      <Col lg={7} className="p-0">
        <div className="main-left-side">
          <img src={Gadgets} alt="gadgets" />
        </div>
      </Col>
      <Col lg={5} className="p-0">
        <div className="right-wrapper">
          <div className="right-wrapper-content">
            <Row className="logo m-auto">
              <img src={Logo} alt="logo" />
            </Row>
            <form className="m-5" onSubmit={submitHandler}>
              <div className="setHeading">
                <h2>Set New Password</h2>
              </div>
              {Object.keys(passwordErr).map((key) => {
                return <div style={{ color: "red" }}>{passwordErr[key]}</div>;
              })}
              <input
                className="input-basic"
                type="password"
                placeholder={messages.NEW_PASSWORD}
                value={password}
                onChange={setPassword}
              />
              {Object.keys(passwordErr).map((key) => {
                return <div style={{ color: "red" }}>{passwordErr[key]}</div>;
              })}
              {Object.keys(confirmPasswordErr).map((key) => {
                return <div style={{ color: "red" }}>{confirmPasswordErr[key]}</div>;
              })}
              <input
                className="input-basic"
                type="password"
                placeholder={messages.CONFIRM_NEW_PASSWORD}
                value={confirmPassword}
                onChange={setConfirmPassword}
              />

              <div className="w-100 singleButtonDiv">
                <button className="submitBtn" variant="primary">
                  Set new password
                </button>
              </div>
            </form>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default SetNewPassword;
