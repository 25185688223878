import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Col, Container, Form, Row } from "react-bootstrap";
import { ReactTitle } from "react-meta-tags";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import "../../style/style.css";
import ActionConfirmationPopup from "../shared/action-confirmation-popup";
import { messages } from "../utils/constants";
import { focusById, isMatches, notify } from "../utils/utility";
import SensorForm from "./component/sensor-form.jsx";
import MeterPreview from "./popup/meter-preview-popup/MeterPreview";
const _ = require("lodash");

const AddMeter = () => {
  const history = useHistory();
  const sensorFormRef = useRef();
  const [meterDataRef, setMeterDataRef] = useState(null);

  const [meterAddDisabled, setMeterAddDisabled] = useState(true);
  const [sensorFormChanged, setSensorFormChanged] = useState(false);
  const [editSensorId, setEditSensorId] = useState();
  const [editSensorData, setEditSensorData] = useState("");
  const [sensorIsDisabled, setSensorIsDisabled] = useState(true);
  const [sensorAction, setSensorAction] = useState("add");
  const [sensor, setSensor] = useState([]);
  const [sensorList, setSensorList] = useState([]);
  const [sensorUnitList, setSensorUnitList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState();

  const [skin, setSkin] = useState(1);
  const [name, setName] = useState();
  const [meterNameOld, setMeterNameOld] = useState("");
  const [action, setAction] = useState("add");
  const [show, setShow] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const [deleteName, setDeleteName] = useState();
  const [sensorListFetched, setSensorListFetched] = useState(false);
  const [organizationListFetched, setOrganizationListFetched] = useState(false);
  const editMeterId = localStorage.getItem("editMeterId") || "";
  const copyMeterId = localStorage.getItem("copyMeterId") || "";

  const [btnDisabled, setBtnDisabled] = useState(true); //for check sensor validation
  const [droppableClick, setDroppableClick] = useState();
  const [editedForm, setEditedForm] = useState(false);
  const [popupValue, setPopupValue] = useState();
  const [cancelPopupShow, setCancelPopupShow] = useState(false);
  const [allSensorHasName, setAllSensorHasName] = useState(false);
  const [category, setCategory] = useState();
  const [checked, setChecked] = useState(false);
  const [organizationList, setOrganizationList] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [isDefault, setIsDefault] = useState(false);

  const role = localStorage.getItem("role");
  const userId = localStorage.getItem("userId");
  const organizationId = localStorage.getItem("organizationId");
  useEffect(() => {
    setMeterAddDisabled(true);
    if (name !== meterNameOld && name !== "") {
      let allSensorHasName = true;
      sensor?.forEach((item) => {
        if (item?.name === "Customised Sensor") {
          if (!item?.sensorName) {
            allSensorHasName = false;
          }
        } else {
          if (!item?.name) {
            allSensorHasName = false;
          }
        }
      });
      if (allSensorHasName) {
        setMeterAddDisabled(false);
      }
    }
  }, [name]);
  useEffect(() => {
    let allSensorHasName = true;
    sensor?.forEach((item) => {
      if (item?.name === "Customised Sensor") {
        if (!item?.sensorName) {
          allSensorHasName = false;
        }
      } else {
        if (!item?.name) {
          allSensorHasName = false;
        }
      }
    });
    setAllSensorHasName(allSensorHasName);
  }, [sensor]);
  useEffect(() => {
    editedForm && localStorage.setItem("cancelFormChanges", editedForm);
  }, [editedForm]);

  useEffect(() => {
    editMeterId && setAction("edit");
    copyMeterId && setAction("copy");
    getSensorList();
    +role === 1 && getOrganizationList();
  }, []);

  useEffect(() => {
    if ((+role !== 1 && sensorListFetched) || (+role === 1 && sensorListFetched && organizationListFetched))
      action !== "add" && getMeterDetails();
  }, [sensorListFetched, organizationListFetched]);

  useEffect(() => {
    if (sensorFormChanged) {
      setMeterAddDisabled(false);
      const sensorData = sensorFormRef?.current?.getValidatedData();
      if (sensorData) {
        let _sensor = [...sensor];
        if (typeof editSensorId === "number") {
          const index = sensor.findIndex((inst) => inst.id === editSensorId);
          _sensor[index] = {
            ..._sensor[index],
            new: true,
            sensorData,
          };
          setSensor(_sensor);
        } else if (editSensorId) {
          const index = _sensor.findIndex((inst) => inst.id === editSensorId);
          _sensor[index] = {
            ..._sensor[index],
            edit: true,
            sensorData,
          };
          setSensor(_sensor);
        }
        for (let i in _sensor) {
          if (
            !_sensor[i]?.sensorName?.trim() ||
            ((_sensor[i]?.sensorData?.selectType === 1 ||
              _sensor[i]?.sensorData?.selectType === 2 ||
              _sensor[i]?.sensorData?.selectType === 3) &&
              _sensor[i].sensorData &&
              (!_sensor[i]?.sensorData?.sensorLabel ||
                _sensor[i]?.sensorData?.sensorPrecision === "" ||
                !_sensor[i]?.sensorData?.sensorBounce === "" ||
                !_sensor[i]?.sensorData?.sensorIncrement ||
                !_sensor[i]?.sensorData?.sensorLumpIncrement ||
                !_sensor[i]?.sensorData?.sensorMinVal === "" ||
                !_sensor[i]?.sensorData?.sensorAlarm2Val ||
                !_sensor[i]?.sensorData?.sensorMaxVal ||
                !_sensor[i]?.sensorData?.sensorNormVal)) ||
            ((_sensor[i]?.sensorData?.selectType === "4" ||
              _sensor[i]?.sensorData?.selectType === 4) &&
              !_sensor[i]?.sensorData?.sensorValue &&
              !_sensor[i]?.sensorData?.sensorLabel)
          ) {
            // setMeterAddDisabled(true);
          } else {
            // setMeterAddDisabled(false);
          }
        }
        if (meterAddDisabled) return;
        // setMeterAddDisabled(false);
      }
      setEditedForm(true);
      setSensorFormChanged(false);
    }
  }, [sensorFormChanged]);

  const updateForm = () => {
    if (sensor?.length && (editSensorId >= 0 || editSensorId)) {
      let data = sensor.find((inst) => inst.id === editSensorId);
      if (typeof editSensorId === "number" || data.edit) {
        if (data?.sensorData) {
          const finalData = {
            selectType: data.sensorData.selectType,
            label: data.sensorData.sensorLabel,
            type: data.sensorData.selectType,
            units: data?.sensorData?.sensorUnit?.length
              ? data.sensorData.sensorUnit
              : [{ id: "615556d646309a2c96d2ac4c" }],
            precision: data.sensorData.sensorPrecision,
            bounce: data.sensorData.sensorBounce,
            increment: data.sensorData.sensorIncrement,
            jumpIncrement: data.sensorData.sensorLumpIncrement,
            minValue: data.sensorData.sensorMinVal,
            interAlarmValue: data.sensorData.sensorInterVal,
            sensorLowAlarms: data.sensorData.sensorLowAlarms,
            sensorLowLowAlarms: data.sensorData.sensorLowLowAlarms,
            maxValue: data.sensorData.sensorMaxVal,
            alarm2: data.sensorData.sensorAlarm2Val,
            normValue: data.sensorData.sensorNormVal,
            linkedSensor: data.sensorData.linkedSensor,
            operation: data.sensorData.operation,
            value: data.sensorData.value,
          };
          setEditSensorData(finalData);
        }
      } else {
        let _data = sensor.find((inst) => {
          return inst.value === editSensorId || inst.id === editSensorId;
        });
        setEditSensorData({
          ..._data,
          label: _data?.sensorLabel || "",
          selectType: _data?.type,
          value: _data?.value,
        });
      }
    }
  };

  const getOrganizationList = () => {
    const url = "/Organizations/getOrganizationList";
    axios.post(url, {
      fields: [
        "id",
        "name"
      ],
      limit: 0,
      skip: 0,
      searchContent: "",
      status: 1
    }).then((res) => {
      if (res.data.items) {
        let { items = [] } = res.data;
        let _organizationList = items?.map?.((item, index) => ({
          label: item.name,
          value: item.id,
          id: index
        })) || [];
        setOrganizationList(_organizationList);
        setOrganizationListFetched(true);
      }
    }).catch((error) => {
      console.error("error", error)
    })
  }

  useEffect(() => {
    if (editSensorId) {
      updateForm();
    }
  }, [editSensorId]);

  useEffect(() => {
    checkFieldValues();
  });

  useEffect(() => {
    if (selectedIndex === 0 && sensor[0]?.id && sensor[0]?.isDefault) {
      handleIconClick(0, "view");
    }
  }, [sensor]);

  useEffect(() => {

    if (showPreview) return () => { console.log("preview Closed"); setMeterDataRef(null); }
  }, [showPreview])

  const cancelHandler = () => {
    if (editedForm) {
      setCancelPopupShow(true);
      setPopupValue({
        callBackFunction: () => {
          localStorage.removeItem("editSensorId");
          localStorage.removeItem("copyAndEditSensorId");
          localStorage.removeItem("cancelFormChanges");
          history.goBack();
        },
        headerName: "Alert",
        message: messages.cancelFormChanges,
        action: "Yes",
        cancelBtn: "No",
      });
    } else {
      localStorage.removeItem("editMeterId");
      localStorage.removeItem("copyMeterId");
      history.push("/Home/Meter");
    }
  };
  const onBlurHandler = (name, index) => {
    axios
      .post("/Sensors/sensorExist", { name })
      .then(function (response) {
        if (response?.data?.exist) notify(messages.ToastError, `Sensor with name "${name}" already exists.`)
      }).catch(function (error) {
        console.log("error");
      })
  }
  const getSensorList = () => {
    const url = "/Sensors/getSensorList";
    axios
      .post(url, {
        forAlarms: false,
        skip: 0,
        limit: 0,
        searchContent: "",
        fields: [
          "id",
          "name",
          "isDefault",
          "organizationId",
          "createdBy",
          "isDefaultDrager",
          "label",
          "precision",
          "type",
          "bounce",
          "units",
          "increment",
          "jumpIncrement",
          "minValue",
          "normValue",
          "alarm2",
          "interAlarmValue",
          "sensorLowAlarms",
          "sensorLowLowAlarms",
          "maxValue",
        ],
      })
      .then(function (response) {
        // handle success
        let data = response.data ? response.data : {};
        if (data.errorCode) {
          notify(messages.ToastError, "server issue");
        } else {
          let sensorList = [
            {
              label: <span>Customised Sensor</span>,
              name: "Customised Sensor",
              value: "",
              isDefault: false,
            },
          ];
          for (let i in data) {
            let item = {};
            item.label = (
              <span>
                {data[i].name}
                <span className="elementDefault">
                  {(parseInt(role) === 2 &&
                    data[i].organizationId !== organizationId) ||
                    data[i]?.isDefaultDrager ||
                    (parseInt(role) === 3 && data[i].createdBy !== userId)
                    ? " (Default)"
                    : ""}
                </span>
              </span>
            );
            item.name = data[i].name;
            item.value = data[i].id;
            item.sensorLabel = data[i].label;
            item.isDefault =
              (parseInt(role) === 2 &&
                data[i].organizationId !== organizationId) ||
                (parseInt(role) === 3 && data[i].createdBy !== userId)
                ? true
                : false;
            item.isDefaultDrager = data[i]?.isDefaultDrager || false;
            item.type = data[i]?.type;
            item.units = data[i]?.units;
            item.precision = data[i]?.precision;
            item.bounce = data[i]?.bounce;
            item.increment = data[i]?.increment;
            item.jumpIncrement = data[i]?.jumpIncrement;
            item.minValue = data[i]?.minValue;
            item.normValue = data[i]?.normValue;
            item.interAlarmValue = data[i]?.interAlarmValue;
            item.sensorLowAlarms = data[i]?.sensorLowAlarms;
            item.sensorLowLowAlarms = data[i]?.sensorLowLowAlarms;
            item.alarm2 = data[i]?.alarm2;
            item.maxValue = data[i]?.maxValue;
            item.createdBy = data[i]?.createdBy;
            sensorList.push(item);
          }
          setSensorList(sensorList);
          setSensorListFetched(true);
        }
      })
      .catch(function (error) { });
  };

  const getMeterDetails = () => {
    axios
      .post("/Meters/getMeterDetail", { id: editMeterId || copyMeterId })
      .then(function (response) {
        // handle success
        let data = response.data ? response.data : {};
        if (!data.errorCode) {
          setMeterNameOld(
            data.name
              ? action === "copy"
                ? data.name + " copy"
                : data.name
              : ""
          );
          setName(
            data.name
              ? action === "copy"
                ? data.name + " copy"
                : data.name
              : ""
          );
          setCategory(data.category);
          setChecked(data.forDefaultOrganization);
          let selectedOrganizations = organizationList.filter((inst) =>
            data?.forOrganizations?.some?.((val) => val.id === inst?.value)
          ) || [];
          setOrganization(selectedOrganizations);
          /* skin */
          if (data.skin) {
            let selectSkin = {};
            selectSkin = _.find(messages.skinType, ((inst) => inst["id"] == data.skin));
            let id = selectSkin.id || "";
            handleSelectSkin(id);
          }
          if (((parseInt(role) === 2 && data.organizationId !== organizationId) || (parseInt(role) === 3 && data.isDefault)) && action === "edit") {
            setIsDefault(true)
          }
          setSelectedIndex(0);
          /* set sensor */
          let _sensors = data.sensors || [];
          let _sensorList = sensorList;
          if (_sensors.length) {
            let finalSensorArray = [];
            for (let i = 0; i < _sensors.length; i++) {
              let item = {};
              item.type = _sensors[i].type;
              item.label = (
                <span>
                  {_sensors[i].name}{" "}
                  <span className="elementDefault">
                    {(parseInt(role) === 2 &&
                      _sensors[i].organizationId !== organizationId) ||
                      _sensors[i].isDefaultDrager ||
                      (parseInt(role) === 3 && _sensors[i].createdBy !== userId)
                      ? " (Default)"
                      : ""}
                  </span>
                </span>
              );
              item.id = _sensors[i].id;
              item.value = _sensors[i].id;
              item.sensorValue = _sensors[i]?.value;
              item.name = _sensors[i].name;
              item.sensorName = _sensors[i].name;
              item.sensorLabel = _sensors[i].label;
              item.isDefault =
                (parseInt(role) === 2 &&
                  _sensors[i].organizationId !== organizationId) ||
                  _sensors[i].isDefaultDrager ||
                  (parseInt(role) === 3 && _sensors[i].createdBy !== userId)
                  ? true
                  : false;
              item.organizationId = _sensors[i].organizationId;
              item.label = _sensors[i].label;
              item.type = _sensors[i].type;
              item.units = _sensors[i].units;
              item.precision = _sensors[i].precision;
              item.bounce = _sensors[i].bounce;
              item.bounceInterval = _sensors[i].bounceInterval;
              item.normValue = _sensors[i].normValue;
              item.increment = _sensors[i].increment;
              item.jumpIncrement = _sensors[i].jumpIncrement;
              item.minValue = _sensors[i].minValue;
              item.interAlarmValue = _sensors[i].interAlarmValue;
              item.sensorLowAlarms = _sensors[i].sensorLowAlarms;
              item.sensorLowLowAlarms = _sensors[i].sensorLowLowAlarms;
              item.alarm2 = _sensors[i].alarm2;
              item.maxValue = _sensors[i].maxValue;
              item.calculation = _sensors[i].calculation;
              item.linkedSensor = _sensors[i].linkedSensor;
              item.operation = _sensors[i].operation;
              item.status = _sensors[i].status;
              item.isDrager = _sensors[i].isDrager;
              item.isDefaultDrager = _sensors[i].isDefaultDrager;
              item.createdBy = _sensors[i].createdBy;
              finalSensorArray.push(item);
              if (_sensors[i].type === 4) {
                let _item = { ...item }
                _item.label = _item.name;
                _sensorList.push(_item);
              }
            }
            setSensor(finalSensorArray);
            setEditSensorId(_sensors[0].id);
            setSensorAction(finalSensorArray[0].isDefault ? "view" : "edit");
          }
          if (data.skin === 4) {
            handleSelectSkin("4");
          } else {
            setSensorList(_sensorList);
          }
        } else {
          notify(messages.ToastError, messages.ServerNotResponding);
        }
      })
      .catch(function (error) { });
  };

  const enableDisableSave = (_enable) => {
    setSensorIsDisabled(_enable);
  };

  /* start Sensors */
  const handleWarning = (event, index, name) => {
    event.preventDefault();
    if (!isDefault) {
      setShow(true);
      setDeleteIndex(index);
      setDeleteName(name);
    }
  };

  const handleRemoveClick = () => {
    let list = sensor || [];
    list.splice(deleteIndex, 1);
    if (list.length === 0) {
      list.push({ id: "", value: "", name: "", isDefault: false });
    }
    setMeterAddDisabled(false);
    setSensor([...list]);
    setSelectedIndex(deleteIndex === 0 ? 0 : deleteIndex - 1);
    setShow(false);
  };

  const handleAddClick = (e) => {
    e.preventDefault();
    if (!isDefault) {
      if (sensor.length < 7) {
        setSensor([
          ...sensor,
          {
            id: sensor.length + 1,
            value: sensor.length + 1,
            name: "",
            isDefault: false,
          },
        ]);
        //setEditSensorId(sensor.length);
        setSelectedIndex(sensor.length);
        // setMeterAddDisabled(true);
      } else {
        notify(messages.ToastError, messages.SensorAddLimit);
      }
    }
  };

  const handleIconClick = (index, actionBtn) => {
    setSensorAction(actionBtn);
    setSelectedIndex(index);
    if (sensor[index].id || sensor[index].id >= 0) {
      setEditSensorId(sensor[index].id);
    } else {
      setEditSensorId();
    }
  };

  const handleSelectSensor = (select, i) => {
    const list = [...sensor];
    let _sensor = sensor;
    let index = -1;
    if (select.value)
      index = _sensor.findIndex((inst) => inst.id === select.value);
    let selectedLabel = select.name || "This";
    if (index !== -1) {
      focusById("type-" + i);
      notify(messages.ToastError, messages.InvalidSensorSelect(selectedLabel));
      return;
    }
    if (
      select.value &&
      !(
        (parseInt(role) === 2 && select.organizationId !== organizationId) ||
        select?.isDefaultDrager ||
        (parseInt(role) === 3 && select.createdBy !== userId)
      )
    ) {
      setSensorAction("edit");
      setMeterAddDisabled(false);
      setEditSensorId(select.value);
    } else if (select.name === "Customised Sensor") {
      setSensorAction("add");
      setEditSensorId(i);
      setEditSensorData("");
      setMeterAddDisabled(true);
    } else if (
      select.name !== "Customised Sensor" &&
      ((parseInt(role) === 2 && select.organizationId !== organizationId) ||
        select?.isDefaultDrager ||
        (parseInt(role) === 3 && select.createdBy !== userId))
    ) {
      setSensorAction("view");
      setEditSensorId(select.value);
      setMeterAddDisabled(false);
    }
    list[i].id = select.value || i;
    list[i].value = select.value || i;
    list[i].name = select.name || "";
    list[i].isDefault =
      select.name != "Customised Sensor" &&
        ((parseInt(role) === 2 && select.organizationId !== organizationId) ||
          select.isDefaultDrager ||
          (parseInt(role) === 3 && select.createdBy !== userId))
        ? true
        : false;
    list[i].label = select.label || {};
    list[i].sensorLabel = select.sensorLabel;
    list[i].isDefaultDrager = select.isDefaultDrager || false;
    list[i].type = select.type;
    list[i].units = select.units;
    list[i].precision = select.precision;
    list[i].bounce = select.bounce;
    list[i].increment = select.increment;
    list[i].jumpIncrement = select.jumpIncrement;
    list[i].minValue = select.minValue;
    list[i].normValue = select.normValue;
    list[i].interAlarmValue = select.interAlarmValue;
    list[i].sensorLowAlarms = select.sensorLowAlarms;
    list[i].sensorLowLowAlarms = select.sensorLowLowAlarms;
    list[i].alarm2 = select.alarm2;
    list[i].maxValue = select.maxValue;
    list[i].bounceInterval = select.bounceInterval;
    list[i].createdBy = select.createdBy;
    setSelectedIndex(i);
    setSensor(list);
    setEditedForm(true);
  };

  const handleInputChange = (e, i) => {
    const { value } = e.target;
    setSelectedIndex(i);
    const _sensor = [...sensor];
    _sensor[i].label = value;
    _sensor[i].sensorName = value;
    setSensor(_sensor);
  };

  const checkFieldValues = () => {
    let check = true;
    if (!sensor.filter((inst) => inst.id === "").length && sensor.length) {
      check = false;
    }
    if (name && skin && !check) {
      setBtnDisabled(false); //enable the button
    } else {
      setBtnDisabled(true); // Disable te button
    }
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    width: "100%",
  });

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      sensor,
      result.source.index,
      result.destination.index
    );
    // const orderedArray = orderArrayFun(items);
    setSensor([...items]);
  };

  const customFilter = (option, searchContent) => {
    let name = option.data.name;
    if (isMatches(name.toLowerCase(), searchContent.toLowerCase().replace(messages.searchContentRegex, messages.searchContentReplaceRegex))) {
      return true;
    } else {
      return false;
    }
  };

  const SensorHtml = (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            className={
              sensor.length > 0
                ? "sensorAddDiv"
                : "sensorAddDiv d-flex align-items-center justify-content-center"
            }
          >
            {sensor.length > 0
              ? sensor.map((x, i) => {
                let disable = sensorIsDisabled || selectedIndex != i;
                let Color =
                  x.id === droppableClick ? "#FF7538" : "rgb(139 136 136)",
                  deleteBtnColor = x.id === droppableClick && !isDefault ? "#FF7538" : "rgb(139 136 136)";
                let sensorJson = null;
                if (x.id) {
                  let selectSensor = _.find(
                    sensorList,
                    function (inst) {
                      return inst["value"] === x.id;
                    }
                  );
                  sensorJson = selectSensor;
                }
                const _name = sensorJson ? sensorJson.name : "this";
                const menuBtn =
                  x.name === "Customised Sensor"
                    ? "add"
                    : x.isDefault
                      ? "view"
                      : "edit";
                return (
                  <Draggable
                    key={"draggable-" + i}
                    draggableId={`${i}`}
                    index={i}
                    isDragDisabled={isDefault}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        onClick={() => {
                          setDroppableClick(x.id);
                        }}
                      >
                        <Row>
                          <Col
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            className="d-flex align-items-center justify-content-center"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Drag and drop"
                          >
                            <i className="fas fa-grip-horizontal me-2"></i>
                          </Col>
                          <Col lg={8} md={8} sm={8} xs={8}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupSkin"
                            >
                              <Form.Label></Form.Label>
                              {x.name === "Customised Sensor" ? (
                                <Form.Control
                                  type="text"
                                  name={"type-" + i}
                                  onChange={(e) => {
                                    handleInputChange(e, i);
                                  }}
                                  placeholder="Enter sensor name"
                                  onBlur={(e) => e.target.value && onBlurHandler(e.target.value, i)}
                                  maxLength={50}
                                />
                              ) : (
                                <Select
                                  className="SelectBox"
                                  id={"type-" + i}
                                  placeholder="Select sensor"
                                  options={sensorList}
                                  value={sensorJson}
                                  filterOption={customFilter}
                                  isMulti={false}
                                  isSearchable="true"
                                  onChange={(e) => {
                                    handleSelectSensor(e, i);
                                  }}
                                  isDisabled={isDefault}
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col lg={3} md={3} sm={3} xs={3}>
                            <div className="sensorBtn">
                              {x.isDefault ? (
                                <i
                                  onClick={() => handleIconClick(i, "view")}
                                  style={{ color: Color }}
                                  className="fas fa-eye p-2 pointer"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="View"
                                ></i>
                              ) : (
                                <i
                                  onClick={() => handleIconClick(i, "edit")}
                                  style={{ color: Color }}
                                  className="fas fa-pencil-alt p-2 pointer"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Edit"
                                ></i>
                              )}
                              {!isDefault &&
                                <i
                                  onClick={(e) => handleWarning(e, i, _name)}
                                  style={{ color: deleteBtnColor }}
                                  className="fas fa-trash-alt p-2 pointer"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Delete"
                                ></i>
                              }
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Draggable>
                );
              })
              : "No sensor added"}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
  /* end Sensors */

  const handleSelectSkin = (value) => {
    if (value === "4") {
      setEditedForm(false);
      setSelectedIndex(0);
      if (action === "add") {
        setSensor(
          sensorList
            .filter((inst) => inst.isDefaultDrager)
            .map((inst3) => {
              return {
                ...inst3,
                id: inst3.value,
                value: inst3.value,
                name: inst3.name,
                isDefault: true,
              };
            })
        );
      }
    }
    setSkin(value);
  };

  function submitHandler(redirect) {
    setEditedForm(false);
    localStorage.removeItem("cancelFormChanges");
    for (let i in sensor) {
      if (!sensor[i].name) {
        notify(
          messages.ToastError,
          "Please enter the complete information of the sensor"
        );
        setMeterAddDisabled(true);
        return;
      }
    }
    let isValid = sensorFormRef.current.validData();
    if (isValid) {
      let meterData = {
        name: name,
        skin: skin,
        sensors: sensor,
        category: category,
        checked: checked,
      };
      if (+role === 1) meterData.forOrganizations = organization?.map?.((inst) => ({ id: inst.value }));
      if (action === "add" || action === "copy") {
        axios
          .post("/Meters/addMeter", meterData)
          .then(function (response) {
            let data = response.data ? response.data : {};
            if (data.errorCode) {
              if (+data.errorCode === 406) {
                notify(
                  messages.ToastError,
                  data?.message === "Sensor with same name already exist."
                    ? data.message
                    : messages.MeterAlreadyExist
                );
              }
              else if (+data.errorCode === 401) {
                notify(
                  messages.ToastError,
                  messages.AddMeterPermissionDenied
                );
                localStorage.setItem("meterDisabledForOrg", true)
                history.push("/Home/Meter");
              }
              else {
                notify(messages.ToastError, messages.ServerNotResponding);
              }
            } else {
              setMeterAddDisabled(true);
              setAction("edit");
              const _sensors = [...sensor];
              const finalData = data.sensors.map((inst, index) => {
                let edit = false;
                if (_sensors[index].new) {
                  edit = true;
                }
                return { ...inst, ..._sensors[index], edit, id: inst.id };
              });
              setSensor(finalData);
              setEditSensorId(data.sensors[0].id);
              updateForm();
              localStorage.setItem("editMeterId", data.id);

              notify(messages.ToastSuccess, messages.MeterAdded);
              if (redirect) {
                history.push("/Home/Meter");
              } else {
                getSensorList();
              }
            }
          })
          .catch(function (error) {
            console.error("error");
          });
      } else {
        axios
          .post("/Meters/updateMeter", meterData)
          .then(function (response) {
            // handle success
            let data = response.data ? response.data : {};
            if (data.errorCode) {
              if (+data.errorCode === 406) {
                notify(messages.ToastError, data?.message?.startsWith("Sensor with")
                  ? data.message
                  : messages.MeterAlreadyExist);
              } else {
                notify(messages.ToastError, messages.ServerNotResponding);
              }
            } else if (data) {
              setMeterAddDisabled(true);
              notify(messages.ToastSuccess, messages.MeterUpdated);
              if (redirect) {
                history.push("/Home/Meter");
              } else {
                getSensorList();
              }
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    }
  }
  function previewHandler() {
    for (let i in sensor) {
      if (!sensor[i].name) {
        notify(
          messages.ToastError,
          "Please enter the complete information of the sensor"
        );
        setMeterAddDisabled(true);
        return;
      }
    }
    let isValid = sensorFormRef.current.validData();
    if (isValid) {
      let meterData = {
        name: name,
        skin: skin,
        sensors: sensor.map(__ => {
          let sensorUnits = _.intersectionBy(sensorUnitList, [].concat(__.units || [], __?.sensorData?.sensorUnit || []), su => su?.value || su?.id)
          let units = __?.units?.map?.(u => sensorUnits?.find?.(_u => _u?.value === u?.id));
          if (!(__.new || __.edit)) return { ...__, units };
          let sensorUnit = __?.sensorData?.sensorUnit?.map?.(u => sensorUnits?.find?.(_u => _u?.value === u?.id));
          return { ...__, sensorData: { ...__.sensorData, sensorUnit }, units };
        }),
        category: category,
        checked: checked,
      };
      setMeterDataRef(meterData);
      setShowPreview(true);
    }
  }

  const errorMsg = messages.deleteMeterSensorMessage.replace(
    "<sensorName>",
    deleteName
  );
  return (
    <Container>
      <ReactTitle
        title={action === "add" ? "Home-Add-Meter" : "Home-Edit-Meter"}
      />
      {show && (
        <ActionConfirmationPopup
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          setShow={setShow}
          show={show}
          message={show ? errorMsg : ""}
          action={show ? "Delete" : ""}
          deactivateFunction={() => handleRemoveClick()}
        />
      )}
      {showPreview
        && (
          <MeterPreview
            setShow={() => {
              setShowPreview(false);
              setMeterDataRef();
            }}
            data={meterDataRef}
          />
        )
      }
      {cancelPopupShow && (
        <ActionConfirmationPopup
          handleClose={() => setCancelPopupShow(false)}
          handleShow={() => setCancelPopupShow(true)}
          setShow={setCancelPopupShow}
          show={cancelPopupShow}
          message={cancelPopupShow ? popupValue.message : ""}
          action={cancelPopupShow ? popupValue.action : ""}
          deactivateFunction={() => popupValue.callBackFunction()}
          headerName={cancelPopupShow ? popupValue.headerName : ""}
          cancelBtn={cancelPopupShow ? popupValue.cancelBtn : ""}
        />
      )}
      <Row className="row border-bottom">
        <div className="add-update-heading">
          {action === "add"
            ? messages.AddMeter
            : action === "edit"
              ? messages.EditMeter
              : messages.CopyMeter}
        </div>
      </Row>
      <Row className="add-update-right-wrapper">
        <Col lg={6}>
          <Row>
            <Col lg={6}>
              <Form.Group className="mb-3" controlId="skin">
                <Form.Label>{messages.SelectSkin}*</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={skin}
                  onChange={(e) => {
                    handleSelectSkin(e.target.value);
                  }}
                  disabled={isDefault}
                >
                  {messages.skinType.map((e, key) => {
                    return (
                      <option key={"skin-" + key} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>{messages.MeterName}*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={name}
                  onChange={(e) => {
                    setEditedForm(true); // ignore
                    setName(e.target.value);
                  }}
                  disabled={isDefault}
                  maxLength={250}
                />
              </Form.Group>
            </Col>
            {+role === 1 && (
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="category">
                  <Form.Label>{messages.Category}</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={category}
                    default={undefined}
                    onChange={(e) => {
                      setMeterAddDisabled(false);
                      setCategory(e.target.value);
                    }}
                  >
                    {messages.category.map((e, key) => {
                      return (
                        <option
                          key={"category-" + key}
                          selected={e.value == category}
                          value={e.value}
                        >
                          {e.label}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
            )}
            {+role === 1 && (
              <Col style={{ display: "flex", alignItem: "center" }} className="mt-3" lg={6}>
                <Form.Group controlId="category">
                  <Form.Label>{ }</Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Make Standard"
                    onChange={(e) => {
                      setMeterAddDisabled(false);
                      setChecked(e.target.checked)
                    }}
                    checked={checked}
                  />
                </Form.Group>
              </Col>
            )}
            {/* {+role === 1 && <Col lg={6}>
              <Form.Group className="mb-3" controlId="organization">
                <Form.Label>{messages.Organization}</Form.Label>
                <Select
                  className="SelectBox"
                  placeholder="Select Organization"
                  classNamePrefix="crs-select"
                  options={organizationList}
                  value={organization}
                  isMulti
                  isSearchable
                  onChange={(val) => {
                    setMeterAddDisabled(false);
                    setOrganization(val);
                  }}
                />
              </Form.Group>
            </Col>} */}
            <Col lg={12} className="mt-5">
              <div className="sensor-ui">
                <h5>{messages.Sensors}</h5>
                {!isDefault && (
                  <i
                    style={{ color: "#FF7538" }}
                    className="fa fa-plus"
                    onClick={handleAddClick}
                  ></i>
                )}
              </div>
              {SensorHtml}
            </Col>
          </Row>
        </Col>
        <Col
          lg={6}
          className={
            sensor.length && sensor[selectedIndex] && sensor[selectedIndex].name
              ? "ps-5"
              : "ps-5 d-flex align-items-center justify-content-center"
          }
        >
          {sensor.length &&
            sensor[selectedIndex] &&
            sensor[selectedIndex].name ? (
            <SensorForm
              setSensorUnitList={setSensorUnitList}
              ref={sensorFormRef}
              editSensorId={editSensorId}
              editSensorData={editSensorData}
              enableDisableCallback={enableDisableSave}
              showCalculated={true}
              action={sensorAction}
              linkedSensorOptions={sensor.filter(
                (inst, index) => inst.id && index !== selectedIndex
              )}
              setEditedForm={() => {
                setSensorFormChanged(true);
                setMeterAddDisabled(false);
              }}
            />
          ) : (
            "No sensor selected"
          )}
        </Col>
      </Row>
      <Row className="px-2">
        <Col lg={12} className="save-cancel-exit-btn">
          <button className="saveBtn m-1" onClick={cancelHandler}>
            {messages.cancel}
          </button>
          <button className="saveBtn m-1" onClick={() => previewHandler()} >
            {messages.Preview}<span className="font-digital">&nbsp;</span>
          </button>
          <button
            className="saveBtn m-1 submitSaveBtn"
            onClick={() => submitHandler(false)}
            disabled={
              sensorIsDisabled ||
              btnDisabled ||
              meterAddDisabled ||
              !allSensorHasName ||
              !name
            }
          >
            {messages.Save}
          </button>
          <button
            onClick={() => submitHandler(true)}
            disabled={
              sensorIsDisabled ||
              btnDisabled ||
              meterAddDisabled ||
              !allSensorHasName ||
              !name
            }
            className="saveBtn m-1 submitSaveBtn"
          >
            {" "}
            {messages.SaveAndExit}
          </button>
        </Col>
      </Row>
    </Container>
  );
};
export default AddMeter;
