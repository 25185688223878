import axios from "axios";
import { React, useEffect, useMemo, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { ReactTitle } from "react-meta-tags";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory } from "react-router-dom";
import "../../style/global.css";
import "../../style/style.css";
import { messages } from "../utils/constants";
import { notify, toDefaultDateFormat } from "../utils/utility";
import moment from "moment";

const Addagreement = () => {
  const history = useHistory();
  const [agreementName, setAgreementName] = useState("");
  const [agreementTitle, setAgreementTitle] = useState("");
  const [agreementVersion, setAgreementVersion] = useState("");
  const [uploadDateTime, setUploadDateTime] = useState("");
  const [effectiveDateTime, setEffectiveDateTime] = useState("");
  const [value, setValue] = useState("");
  const [checkBoxLabel, setCheckBoxLabel] = useState("");
  const [action, setAction] = useState("add");
  const [id, setId] = useState("");
  const [spinner, setSpinner] = useState(false);
  useEffect(() => {
    const editAgreementId = localStorage.getItem("editAgreementId");
    if (editAgreementId) {
      setAction("edit");
      setId(editAgreementId);
      getAgreementList(editAgreementId);
    }
    const viewAgreementId = localStorage.getItem("viewAgreementId");
    if (viewAgreementId) {
      setAction("view");
      setId(viewAgreementId);
      getAgreementList(viewAgreementId);
    }
    const currentDateTime = moment().format(messages.momentDateWithTimeFormat);
    setEffectiveDateTime(currentDateTime);
    setUploadDateTime(currentDateTime);
  }, []);

  const getAgreementList = (_id) => {
    setSpinner(true);
    const url = "/Agreements/getById";
    axios
      .post(url, { id: _id })
      .then(function (response) {
        // handle success
        let data = response.data ? response.data : {};
        if (data.errorCode) {
          notify(messages.ToastError, "server issue");
        } else {
          setAgreementName(data?.agreementName);
          setAgreementTitle(data?.title);
          setAgreementVersion(data?.version);
          data?.uploadDateTime &&
            setUploadDateTime(
              moment(data?.uploadDateTime).format(
                messages.momentDateWithTimeFormat
              )
            );
          data?.effectiveDate &&
            setEffectiveDateTime(
              moment(data?.effectiveDate).format(
                messages.momentDateWithTimeFormat
              )
            );
          setCheckBoxLabel(data?.acceptedTitle);
          setValue(data?.agreementDetails);
        }
      })
      .catch(function (error) {});
  };
  function submitHandler(status) {
    let url = new URL("/Agreements/addAgreement", window.location.href);
    const data = {
      agreementName: agreementName,
      title: agreementTitle,
      acceptedTitle: checkBoxLabel,
      agreementDetails: value,
      effectiveDate: new Date(effectiveDateTime).toISOString(),
      version: agreementVersion,
      uploadDateTime: new Date(uploadDateTime).toISOString(),
      status: status, // draft = 0, publish = 1
    };
    if (action === "edit" || id) {
      data.id = id;
      url = new URL("/Agreements/updateById", window.location.href);
    }
    setSpinner(true);
    axios
      .post(url, data)
      .then(function (response) {
        let data = response.data ? response?.data : {};
        if (data.errorCode) {
          if (+data.errorCode === 406) {
            notify(
              messages.ToastError,
              data?.message === "Agreement with same name already exist."
                ? data.message
                : messages.AgreementAlreadyExist
            );
          } else if (+data.errorCode === 409) {
            notify(
              messages.ToastError,
              data?.message === ""
                ? data.message
                : messages.AgreementVersionAlreadyExist
            );
          } else if (+data.errorCode === 400) {
            notify(
              messages.ToastError,
              data?.message === "This version is already exist."
                ? data.message
                : messages.EffectiveDate
            );
          } else if (+data.errorCode === 401) {
            notify(messages.ToastError, messages.AddAgreementPermissionDenied);
            history.push("/Home/Agreements");
          } else {
            notify(messages.ToastError, messages.ServerNotResponding);
          }
        } else {
          if (action === "edit" || id) {
            notify(messages.ToastSuccess, messages.AgreementUpdated);
          } else {
            notify(messages.ToastSuccess, messages.AgreementAdded);
          }
          history.push("/Home/Agreements");
        }
      })
      .catch((error) => {
        console.error(error);
        setSpinner(false);
      });
  }
  function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const currentDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    return currentDateTime;
  }
  function isContentEmpty(value) {
    const strippedValue = value.replace(/<[^>]*>/g, '').trim();
    return strippedValue === '';
  }
  return (
    <Container fluid className="px-0 body-component">
      <ReactTitle title={`Home-${action === "add" ? "Add" : action === "edit" ? "Edit" : "View"}-Agreements`} />
      <div className="container-fluid mt-3">
        <div className="row">
          <p className="font-bold mb-1">
            {action === "add" ? "New" : action === "edit" ? "Edit" : "View"}{" "}
            Agreement
          </p>
        </div>
        <hr />
        <div className="">
          <div className="Add-Agreements">
            {/* <Form
              className="row add-user-form-root"
               onSubmit={submitHandler}
            > */}
            <div className="mt-3">
              <Row>
                <Col md={6} xl={3} lg={3} >
                  <Form.Group
                    className="mb-3 gap-2 align-items-center"
                    controlId="AgreementName1"
                  >
                    <label className="mb-2">Agreement Name*</label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={agreementName}
                      onChange={(e) => {
                        setAgreementName(e.target.value);
                      }}
                      maxLength={255}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} xl={3} lg={3}>
                  <Form.Group
                    className="mb-3 gap-2 align-items-center"
                    controlId="AgreementName2"
                  >
                    <label className="mb-2">Agreement Version*</label>
                    <Form.Control
                      type="text"
                     
                      placeholder=""
                      className=""
                      value={agreementVersion}
                      // className={invalid.name2 ? "is-invalid" : ""}
                      onChange={(e) => {
                        setAgreementVersion(e.target.value);
                      }}
                      maxLength={255}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} xl={3} lg={3}>
                  <Form.Group
                    className="mb-3 gap-2 align-items-center"
                    controlId="AgreementName3"
                  >
                    <label className="mb-2">Upload Date/Time*</label>
                    <Form.Control
                      type="datetime-local"
                      value={uploadDateTime}
                      readOnly
                      maxLength={255}
                    />
                  </Form.Group>
                </Col>
                {/* <Col>
                  <Form.Group
                    className="mb-3 gap-2 align-items-center"
                    controlId="AgreementName4"
                  >
                    <label>Effective Date/Time*</label>
                    <Form.Control
                      type="datetime-local"
                      style={{ width: "170px" }}
                      placeholder=""
                      value={effectiveDateTime}
                      onChange={(e) => {
                        setEffectiveDateTime(e.target.value);
                      }}
                      maxLength={255}
                    />
                  </Form.Group>
                </Col> */}
                <Col md={6} xl={3} lg={3}>
                  <Form.Group
                    className="mb-3 gap-2 align-items-center"
                    controlId="AgreementName4"
                  >
                    <label className="mb-2">Effective Date/Time*</label>
                    <Form.Control
                      type="datetime-local"
                      placeholder=""
                      value={effectiveDateTime}
                      onChange={(e) => {
                        setEffectiveDateTime(e.target.value);
                      }}
                      min={getCurrentDateTime()}
                      step="1d"
                      maxLength={255}
                    />
                  </Form.Group>
                </Col>
                <Col xs= {12}>
                  <Form.Group
                    className="mb-3 gap-2 align-items-center"
                    controlId="AgreementTitle"
                  >
                    <label className="mb-2">Agreement Title*</label>
                    <Form.Control
                      type="text"

                      placeholder=""
                      value={agreementTitle}
                      onChange={(e) => {
                        setAgreementTitle(e.target.value);
                      }}
                      maxLength={255}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <Row>
              <ReactQuill
                theme="snow"
                value={value}
                style={{ height: "320px" }}
                onChange={setValue}
              />
            </Row>
            <Row>
              <Col style={{ marginTop: "70px" }}>
                <Form.Group
                  className="mb-3 gap-2 align-items-center"
                  controlId="checkBoxLabel"
                >
                  <label style={{ width: "150px" }}>Checkbox label*</label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={checkBoxLabel}
                    onChange={(e) => {
                      setCheckBoxLabel(e.target.value);
                    }}
                    maxLength={255}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col
                className="save-cancel-exit-btn"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <button
                  onClick={() => history.push("/Home/Agreements")}
                  type="button"
                  className="button-custom saveBtn"
                  variant="primary"
                >
                  Cancel
                </button>
                {action !== "view" && (
                  <button
                    className="button-custom saveBtn submitSaveBtn cursor-pointer"
                    variant="primary"
                    onClick={() => submitHandler(1)}
                    disabled={
                      !agreementName ||
                      !agreementTitle ||
                      !agreementVersion ||
                      !uploadDateTime ||
                      !effectiveDateTime ||
                      isContentEmpty(value) ||
                      !checkBoxLabel
                    }
                  >
                    Save
                  </button>
                )}
                {action !== "view" && (
                  <button
                    onClick={() => submitHandler(0)}
                    className="button-custom saveBtn submitSaveBtn"
                    variant="primary"
                    disabled={
                      !agreementName ||
                      !agreementTitle ||
                      !agreementVersion ||
                      !uploadDateTime ||
                      !effectiveDateTime ||
                      isContentEmpty(value) ||
                      !checkBoxLabel
                    }
                  >
                    Save as Draft
                  </button>
                )}
              </Col>
            </Row>
            {/* </Form> */}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Addagreement;
