import { memo, useMemo, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { messages } from "../../../utils/constants";
import PreviewNotAvailable from "./components/PreviewNotAvailable";
import AltairSkin from "./components/Altair";
import HazsimDigitalSkin from "./components/HazsimDigital";
import MultiraeSkin from "./components/Multirae";
import DragerXAM8000Skin from "./components/DragerXAM8000";
import DragerXAM2800Skin from "./components/DragerXAM2800";
import DragerXAM3500Skin from "./components/DragerXAM3500";
import { getRandomReadings } from "../../../utils/utility";

const MeterPreview = ({ setShow, data }) => {
    const [showAlarms, setShowAlarms] = useState(false);

    const skinPreview = useMemo(() => {
        if (!Array.isArray(data?.sensors) || !data?.sensors.length) return <PreviewNotAvailable />;
        let sensors = data.sensors.map(sensor => ({ ...sensor, ...getRandomReadings(sensor, showAlarms) }))
        switch (data?.skin?.toString?.()) {
            case "1": return <AltairSkin data={sensors} />;
            case "2": return <HazsimDigitalSkin data={sensors} />;
            case "3": return <MultiraeSkin data={sensors} />;
            case "4": return <DragerXAM8000Skin data={sensors} />;
            case "5": return <DragerXAM2800Skin data={sensors} />;
            case "6": return <DragerXAM3500Skin data={sensors} />;
            default: return <PreviewNotAvailable />
        }
    }, [data, showAlarms]);
    return (
        <Modal show onHide={() => setShow(false)} backdropClassName="modal-default">
            <div style={{ paddingLeft: "2rem", paddingRight: "2rem", paddingTop: "1rem", paddingBottom: "1rem" }}>
                <Modal.Header closeButton className="py-0 px-0 Heading-for-modal" >
                    <div className="w-full relative">
                        <div className="meter-preview-title">Preview</div>
                        <div className="invisible">Preview</div>
                        <div className="meter-name-preview">
                            <h1 className="mb-0">
                                Meter: {
                                    data?.name || <span className="error">{messages.meterPreviewDefaults.noName}</span>
                                }
                            </h1>
                            {
                                data?.name
                                    ? <label htmlFor="show-alarms">
                                        <input
                                            type="checkbox"
                                            name="show-alarms"
                                            id="show-alarms"
                                            checked={showAlarms}
                                            onChange={(e) => setShowAlarms(e.target.checked)}
                                        /> Show alarms
                                    </label>
                                    : ""
                            }
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-0 pb-0">
                    <div className="meter-skin">
                        {skinPreview}
                    </div>
                </Modal.Body>
                <Modal.Footer className="px-0">
                </Modal.Footer>
            </div>
        </Modal >
    )
}
export default memo(MeterPreview);