import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { ReactTitle } from "react-meta-tags";
import GridFilter from "../grid/grid-filter";
import ReactGrid from "../grid/reactGrid";
import Spinner from "../spinner/spinner";
import { messages } from "../utils/constants";
import ShowEntries from "../utils/show-entries";
import {
  ifIsNumberOrNUll,
  notify,
  toNumberOrNull
} from "../utils/utility";

const AlarmList = () => {
  const [spinner, setSpinner] = useState(false);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(
    messages.GridRowCountOptions[0]
  );
  const [searchContent, setSearchContent] = useState("");
  const role = localStorage.getItem("role");
  const [forAlarms, setForAlarms] = useState(true);
  const [fields, setFields] = useState([]);
  const [showTotalCount, setShowTotalCount] = useState(true);

  const [totalCount, setTotalCount] = useState();
  const [activePage, setActivePage] = useState(1);
  const [skip, setSkip] = useState(0);

  const [rowData, setRowData] = useState([]);

  function maxValue(props) {
    return <div className="minValue">{props.value}</div>;
  }
  function GridActions(props) {
    const status = () => {
      saveAlarmFunction(props.data);
    };
    return (
      <ul className="trigger-div mb-0 px-0">
        <i
          className="fas p-2 fa-save mx-4 color-orange pointer"
          onClick={status}
        ></i>
      </ul>
    );
  }

  const saveAlarmFunction = (props) => {
    // sensor types
    // 1 is for standard
    // 2 is for bar
    // 3 is for exponentials
    const data = {
      sensorId: props.id,
      type: props.type,
      sensorMinVal: toNumberOrNull(props.minValue),
      sensorMaxVal: toNumberOrNull(props.maxValue),
      sensorInterVal: toNumberOrNull(props.interValue),
      sensorLowAlarms: toNumberOrNull(props.sensorLowAlarms),
      sensorLowLowAlarms: null /* toNumberOrNull(props.sensorLowLowAlarms) */,
      sensorAlarm2: toNumberOrNull(props.alarm2Value),
      sensorNormVal: toNumberOrNull(props.normValue),
    };

    let isValid = false;
    isValid = validator(data);
    if (isValid) {
      axios
        .post("/Sensors/sensorAlarmValueUpdate", data)
        .then((res) => {
          notify(messages.ToastSuccess, messages.SensorUpdated);
          getSensorList();
        })
        .catch((error) => {
          console.error(error);
          notify(messages.ToastError, messages.somethingWentWrong);
        });
    }
  };
  const validator = (data) => {
    /*Validations*/
    if (data.type == messages.sensorTypes.BAR) {
      if (
        toNumberOrNull(data.sensorMinVal) === null ||
        parseInt(data.sensorMinVal) !== 0
      ) {
        notify(
          messages.ToastError,
          "Enter valid min value (Min Value should be zero for bar sensor)"
        );
        return false;
      }
      if (
        toNumberOrNull(data.sensorMaxVal) === null ||
        parseInt(data.sensorMaxVal) !== +data.sensorMaxVal
      ) {
        notify(
          messages.ToastError,
          "Enter valid max value. Max value should be integer for bar sensor (Allowed positive number)"
        );
        return false;
      }
      if (
        toNumberOrNull(data.sensorMaxVal) === null ||
        parseInt(data.sensorMaxVal) > 6 ||
        parseInt(data.sensorMaxVal) < 1
      ) {
        notify(
          messages.ToastError,
          "Enter valid max value (Max Value can not be less than 1 or greater than 6 for bar sensor)"
        );
        return false;
      }
      if (
        toNumberOrNull(data.sensorNormVal) === null ||
        parseInt(data.sensorNormVal) !== +data.sensorNormVal
      ) {
        notify(
          messages.ToastError,
          "Norm value should be integer for bar sensor (Allowed positive number)"
        );
        return false;
      }
      if (
        +data.sensorNormVal < +data.sensorMinVal ||
        +data.sensorNormVal > +data.sensorMaxVal
      ) {
        notify(
          messages.ToastError,
          "Norm value should be between min value and max value (Allowed positive number)"
        );
        return false;
      }
      if (
        toNumberOrNull(data.sensorAlarm2) === null ||
        parseInt(data.sensorAlarm2) !== +data.sensorAlarm2 ||
        +data.sensorAlarm2 <= +data.sensorNormVal ||
        +data.sensorAlarm2 > +data.sensorMaxVal
      ) {
        notify(
          messages.ToastError,
          "Alarm 2 value should be integer for bar sensor (Allowed positive number between norm value and max value)"
        );
        return false;
      }
      if (
        data.sensorInterVal?.trim?.() !== "--" &&
        (!ifIsNumberOrNUll(data.sensorInterVal) ||
          (toNumberOrNull(data.sensorInterVal) !== null &&
            toNumberOrNull(data.sensorInterVal) !==
              parseInt(data.sensorInterVal)))
        // parseInt(data.sensorInterVal) !== +data.sensorInterVal
      ) {
        notify(
          messages.ToastError,
          "Alarm 1 value should be integer for bar sensor (Allowed positive number)"
        );
        return false;
      }
      if (
        data.sensorInterVal?.trim?.() !== "--" &&
        (!ifIsNumberOrNUll(data.sensorInterVal) ||
          (toNumberOrNull(data.sensorInterVal) !== null &&
            (+data.sensorInterVal <= +data.sensorNormVal ||
              +data.sensorInterVal >= +data.sensorAlarm2)))
      ) {
        notify(
          messages.ToastError,
          `Alarm 1 value should be less than Alarm 2 value and greater than norm value`
        );
        return false;
      }
    } else if (toNumberOrNull(data.sensorMinVal) === null) {
      // Non Bar sensors min value
      notify(messages.ToastError, messages.InvalidSensorMinVal);
      return false;
    } else if (
      // Norm value
      toNumberOrNull(data.sensorNormVal) === null ||
      +data.sensorMinVal > +data.sensorNormVal
    ) {
      notify(messages.ToastError, messages.InvalidSensorNormVal);
      return false;
    } else if (
      // Max
      toNumberOrNull(data.sensorMaxVal) === null ||
      +data.sensorNormVal > +data.sensorMaxVal
    ) {
      notify(messages.ToastError, messages.InvalidSensorMaxVal);
      return false;
    } /*  else if (
      // Low low alarm
      !ifIsNumberOrNUll(data.sensorLowLowAlarms) ||
      (toNumberOrNull(data.sensorLowLowAlarms) !== null &&
        (+data.sensorLowLowAlarms < +data.sensorMinVal ||
          +data.sensorLowLowAlarms >= +data.sensorNormVal))
    ) {
      notify(messages.ToastError, messages.InvalidSensorLowLowAlarmNorm);
      return false;
    } */ else if (
      // low alarm
      !ifIsNumberOrNUll(data.sensorLowAlarms) ||
      (toNumberOrNull(data.sensorLowAlarms) !== null &&
        (+data.sensorLowAlarms < +data.sensorMinVal ||
          +data.sensorLowAlarms >= +data.sensorNormVal))
    ) {
      notify(messages.ToastError, messages.InvalidSensorLowAlarmNorm);
      return false;
    } /* else if (
      // low alarm
      toNumberOrNull(data.sensorLowLowAlarms) !== null &&
      toNumberOrNull(data.sensorLowAlarms) !== null &&
      +data.sensorLowLowAlarms >= +data.sensorLowAlarms
    ) {
      notify(messages.ToastError, messages.InvalidSensorLowAlarmLLA);
      return false;
    } */ else if (
      // Alarm 2
      toNumberOrNull(data.sensorAlarm2) === null ||
      +data.sensorAlarm2 <= +data.sensorNormVal ||
      +data.sensorAlarm2 > +data.sensorMaxVal
    ) {
      notify(messages.ToastError, messages.InvalidSensorAlarm2Val2);
      return false;
    } else if (
      // Alarm 1
      !ifIsNumberOrNUll(data.sensorInterVal) ||
      (toNumberOrNull(data.sensorInterVal) !== null &&
        (+data.sensorInterVal <= +data.sensorNormVal ||
          +data.sensorAlarm2 <= +data.sensorInterVal))
    ) {
      notify(messages.ToastError, messages.InvalidSensorInterVal);
      return false;
    }
    return true;
  };
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Sensor",
      field: "sensor",
      headerClass: "ag-custom-header",
      maxWidth: 800,
      minWidth: 120,
    },
    {
      headerName: "Min Value",
      field: "minValue",
      headerClass: "ag-custom-header alarmHeader",
      minWidth: 150,
      editable: true,
      cellRendererFramework: maxValue,
    },
    {
      headerName: "Low Low Alarm",
      field: "sensorLowLowAlarms",
      headerClass: "ag-custom-header alarmHeader",
      minWidth: 150,
      editable: true,
      hide: true,
      cellRendererFramework: maxValue,
    },
    {
      headerName: "Low Alarm",
      field: "sensorLowAlarms",
      headerClass: "ag-custom-header alarmHeader",
      minWidth: 150,
      editable: true,
      cellRendererFramework: maxValue,
    },
    {
      headerName: "Norm Value",
      field: "normValue",
      headerClass: "ag-custom-header alarmHeader",
      minWidth: 150,
      editable: true,
      cellRendererFramework: maxValue,
    },
    {
      headerName: "Alarm 1",
      field: "interValue",
      headerClass: "ag-custom-header",
      minWidth: 150,
      editable: true,
      cellRendererFramework: maxValue,
    },
    {
      headerName: "Alarm 2",
      field: "alarm2Value",
      headerClass: "ag-custom-header",
      minWidth: 150,
      editable: true,
      cellRendererFramework: maxValue,
    },
    {
      headerName: "Max Value",
      field: "maxValue",
      headerClass: "ag-custom-header",
      minWidth: 150,
      editable: true,
      cellRendererFramework: maxValue,
    },
    {
      headerName: "Action",
      field: "Action",
      headerClass: "ag-custom-header",
      cellRendererFramework: GridActions,
      pinned: "right",
      maxWidth: 150,
      minWidth: 100,
    },
  ]);
  const containerStyle = {
    boxSizing: "border-box",
    height: rowData.length * messages.GridRowheight + messages.GridHeaderheight,
    minHeight: 150,
    width: "calc(100%)",
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setSkip(pageNumber * itemsCountPerPage.value - itemsCountPerPage.value);
  };

  const getSensorList = () => {
    const url = "/Sensors/getSensorList";
    setSpinner(true);
    axios
      .post(url, {
        forAlarms: forAlarms,
        skip: skip,
        limit: itemsCountPerPage.value,
        searchContent: searchContent,
        fields: fields,
        showTotalCount: showTotalCount,
      })
      .then((res) => {
        setSpinner(false);
        if (res.data) {
          let rows = [];
          let data = res.data.items;
          for (let i in data) {
            let sensorData = {};
            sensorData.sensor = data[i].name;
            sensorData.type = data[i].type;
            sensorData.id = data[i].id;
            sensorData.minValue = data[i].minValue;
            sensorData.normValue = data[i].normValue;
            sensorData.maxValue = data[i].maxValue;
            sensorData.interValue = ifIsNumberOrNUll(data[i]?.interAlarmValue)
              ? toNumberOrNull(data[i].interAlarmValue, "--")
              : "--";
            sensorData.sensorLowAlarms = ifIsNumberOrNUll(
              data[i]?.sensorLowAlarms
            )
              ? toNumberOrNull(data[i].sensorLowAlarms, "--")
              : "--";
            sensorData.sensorLowLowAlarms =
              null /* ifIsNumberOrNUll(data[i]?.sensorLowLowAlarms)
              ? toNumberOrNull(data[i].sensorLowLowAlarms)
              : "--" */;
            sensorData.alarm2Value = data[i].alarm2;
            rows.push(sensorData);
          }
          setRowData(rows);
        }
        setTotalCount(res.data.totalCount);
      })
      .catch((error) => {
        console.error(error);
        setSpinner(false);
      });
  };
  useEffect(() => {
    getSensorList();
  }, [skip, itemsCountPerPage]);
  return (
    <Container fluid className="px-0 body-component">
      <ReactTitle title="Home-Alarms" />
      <div className="container-fluid mt-3">
        <div className="row">
          <p className="font-bold mb-1"> {messages.EditAlarm}</p>
        </div>
        <hr />
        <div className="row my-5">
          <GridFilter
            role={role}
            entries={true}
            search={true}
            restore={false}
            //  restoreFunction={restoreFunction}
            itemsCountPerPage={itemsCountPerPage}
            setItemsCountPerPage={(e) => {
              setSpinner(true);
              setSkip(0);
              setItemsCountPerPage(e);
              setActivePage(1);
              setSpinner(false);
            }}
            ajaxCall={getSensorList}
            setSearchContent={(e) => {
              setSpinner(true);
              setSkip(0);
              setSearchContent(e);
              setSpinner(false);
            }}
            setSkip={setSkip}
            setActivePage={setActivePage}
          />
        </div>
        <div className="container-fluid grid-list-div mb-3 alarm-grid">
          <div className=" organization-table">
            <div className="ag-theme-alpine application-grid">
              {spinner ? (
                <Spinner />
              ) : (
                <ReactGrid
                  id="requestConsultGrid"
                  gridId="Request-consult-List"
                  containerStyle={containerStyle}
                  columnDefs={columnDefs}
                  rowData={rowData}
                />
              )}
            </div>
            {totalCount > 10 ? (
              <div className="row mb-5">
                <div className="col-sm-6 font-bold mt-2">
                  <ShowEntries
                    totalCount={totalCount}
                    itemsCountPerPage={itemsCountPerPage.value}
                    activePage={activePage}
                  />
                </div>
                <div className="col-md-6 pagination-component">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={itemsCountPerPage.value}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={2}
                    onChange={handlePageChange}
                    itemClass="pagination-item"
                    linkClass="pagination-link"
                    prevPageText="Previous"
                    nextPageText="Next"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AlarmList;
