import axios from "axios";
import { React, useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import auth from "../../../auth";
import { messages } from "../../utils/constants";
import { notify } from "../../utils/utility";
import { useHistory } from "react-router-dom";
import "../../utils/aggrementPopup.css";

const Agreements = ({ show, setShow, agreementId }) => {
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [agreementData, setAgreementData] = useState({});
  useEffect(() => {
    agreementId && getUserAgreementInfo();
  }, [agreementId]);
  const addAcceptAgreement = () => {
    setSpinner(true);
    const url = "/AcceptAgreements/addAcceptAgreement";
    axios
      .post(url, {
        agreementId: agreementId,
      })
      .then(function (response) {
        if (response.data.success) {
          setSpinner(false);
          setShow(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setSpinner(false);
      });
  };
  const getUserAgreementInfo = () => {
    setSpinner(true);
    const url = "/Agreements/getById";
    axios
      .post(url, {
        id: agreementId,
      })
      .then(function (response) {
        if (response.data.success) {
          setAgreementData(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
        setSpinner(false);
      });
  };
  const logout = () => {
    axios
      .post("/session/logout")
      .then((res) => {
        setTimeout(() => {
          auth.logout(() => {
            localStorage.clear();
            history.push("/");
          });
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        notify(messages.ToastError);
      });
  };

  return (
    <Modal show={show}>
      <div style={{ padding: "20px" }} className="agreement-popup">
        <Modal.Header className="py-0 px-0 Heading-for-modal">
          <div className="agreement-model-header text-white rounded p-2 d-flex flex-column align-items-center">
            <div className="fw-bold">{agreementData?.title}</div>
            <div className="text fw-normal">
              In order to access certain membership resources, you must accept
              the latest license agreement
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="px-0 pb-0">
          <div>
            <Row>
              <Col lg={12}>
                <div className="agreement-model-body">
                  <div className="fs-6 fw-bold mb-3">
                    {agreementData?.agreementName}
                  </div>
                  <div
                    className="paragraph"
                    dangerouslySetInnerHTML={{
                      __html: agreementData?.agreementDetails,
                    }}
                  ></div>
                </div>
                <Form.Group className="my-3" controlId="name">
                  <Form.Check
                    type="checkbox"
                    label={
                      <span style={{ fontWeight: "bold" }}>
                        {agreementData?.acceptedTitle}
                      </span>
                    }
                    onChange={(e) => {
                      setChecked(e.target.checked);
                    }}
                    checked={checked}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <div className="submitAndCloseBtn" id="iAgrre">
            <button
              className="button-custom closeBtn"
              variant="primary"
              onClick={() => logout()}
              style={{ borderRadius: "5px" }}
            >
              Logout
            </button>
            <button
              className={`button-custom submitBtn `}
              variant="primary"
              disabled={!checked}
              onClick={() => addAcceptAgreement()}
              style={{ borderRadius: "5px" }}
            >
              I Agree
            </button>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
export default Agreements;
