import { React, useState, useEffect } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import { messages } from "../utils/constants";
import { notify, genRandomColor } from "../utils/utility";
import { toDefaultDateFormat, durationCalculate } from "../utils/utility";
import Spinner from "../spinner/spinner";
import axios from "axios";
import { Line, Chart } from "react-chartjs-2";
import Graph from "../shared/graph";
import "chartjs-adapter-moment";
import zoomPlugin from "chartjs-plugin-zoom";
import DownloadStudentsPdf from "../shared/download-students-pdf.jsx";
import ActionDeletePopup from "../shared/action-delete-popup";
var camera = new Image();
camera.src = "/graph-images/camera-icon.png";

Chart.register(zoomPlugin);
const TrainingLogViewer = () => {
  const trainingLogId = localStorage.getItem("trainingLogId");
  const [spinner, setSpinner] = useState(false);
  const [students, setStudents] = useState([]);
  const [student, setStudent] = useState();
  const [sessions, setSessions] = useState([]);
  const [session, setSession] = useState();
  // const [trainingId, setTrainingId] = useState();
  const [trainingName, setTrainingName] = useState();
  const [instructorName, setInstructorName] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [show, setShow] = useState(false);
  const [modalData, setModelData] = useState();
  const [graphData, setGraphData] = useState([]);
  const [combineLogFlag, setCombineLogFlag] = useState(true);
  const [graphImgShow, setGraphImgShow] = useState(true);
  const [showStudents, setShowStudents] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [viewImage, setViewImage] = useState('');
  const [_trainingLogId, setTrainingLogId] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseDelete = () => setModalShow(false);
  const handleShowDelete = () => setModalShow(true);
  useEffect(() => {
    getTrainingDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (session) {
      combineLogFlag ? getTrainingLogs() : getTrainingCombineLogs();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, combineLogFlag]);

  const DeleteImage = () => {
    setModalShow(false);
    setSpinner(true);
    axios
      .post("/Training/deleteTrainingLogs", {
        trainingLogId: _trainingLogId
      })
      .then(function (response) {
        setSpinner(false);
        // handle success
        let data = response.data ? response.data : {};
        if (!data.errorCode) {
          if (data?.count) {
            getTrainingLogs();
            notify(messages.ToastSuccess, messages.TrainingLogDeleteSucc);
          } else {
            notify(messages.ToastError, messages.ServerNotResponding);
          }
        } else {
          notify(messages.ToastError, messages.ServerNotResponding);
        }
      })
      .catch(function (error) {
        setSpinner(false);
      })
  }

  const getTrainingLogs = () => {
    let sessionObj = sessions.find((inst) => inst.value === session);
    setSpinner(true);
    axios
      .post("/Training/getTrainingLogs", {
        trainingId: trainingLogId,
        macId: student,
        startDate: sessionObj?.startDate,
        endDate: sessionObj?.endDate,
      })
      .then(function (response) {
        setSpinner(false);
        // handle success
        let data = response.data ? response.data : {};
        if (!data.errorCode) {
          let sensors = data || [];

          let _graphData = {};
          for (let [index, sensor] of sensors.entries() || []) {
            let pointStyles = [],
              pointBackgroundColor = [],
              pointName = [],
              images = [],
              data = [],
              trainingLogIds = [],
              graphValue = [];
            let logData = sensor.logData || [];
            let y = 0;
            for (let log of logData) {
              if (log.action === messages.TrainingLogAction.sensorReadingSent) {
                pointStyles.push(null);
                pointName.push(null);
                trainingLogIds.push(null);
                images.push(null);
                pointBackgroundColor.push("white");
                graphValue.push("---");
                y = log.reading;
              } else if (log.action === messages.TrainingLogAction.tagAdded) {
                if (index === 0) {
                  pointName.push("tag");
                  if (log.image) {
                    pointStyles.push(camera);
                  } else {
                    pointStyles.push("rect");
                  }
                  trainingLogIds.push(log.id || null);
                  images.push(log.image || null);
                  pointBackgroundColor.push("#90EE90");
                  graphValue.push(log.tag);
                }
              } else if (
                log.action === messages.TrainingLogAction.questionSent
              ) {
                if (index === 0) {
                  pointName.push("asked");
                  pointStyles.push("rect");
                  trainingLogIds.push(null);
                  images.push(null);
                  pointBackgroundColor.push("blue");
                  graphValue.push(log.question);
                }
              } else if (
                log.action === messages.TrainingLogAction.alarm ||
                log.action === messages.TrainingLogAction.interAlarm
              ) {
                pointStyles.push("triangle");
                images.push(null);
                trainingLogIds.push(null);
                if (log.action === messages.TrainingLogAction.alarm) {
                  pointName.push("alarm");
                  pointBackgroundColor.push("red");
                } else {
                  pointName.push("interIntermediate-alarm");
                  pointBackgroundColor.push("red");
                }
                graphValue.push(log.question);
                y = log.reading;
              } else if (log.action === messages.TrainingLogAction.answerSent) {
                graphValue.push(log.givenAnswer);
                if (index === 0) {
                  images.push(null);
                  trainingLogIds.push(null);
                  if (log.isGivenAnswerCorrect) {
                    pointStyles.push("rect");
                    pointName.push("correct");
                    pointBackgroundColor.push("green");
                  } else {
                    pointStyles.push("rect");
                    pointName.push("incorrect");
                    pointBackgroundColor.push("red");
                  }
                }
              } else {
                pointName.push(null);
                pointStyles.push(null);
                trainingLogIds.push(null);
                images.push(null);
                pointBackgroundColor.push(null);
              }
              data.push({ x: log.createdAt, y: y });
            }

            const _color = genRandomColor();
            _graphData = {
              data: {
                unit: sensor.sensorUnit || "",
                sensorLabel: sensor.sensorLabel || "",
                meterName: sensor.meterName || "",
                datasets: [].concat(_graphData?.data?.datasets || [], {
                  label: sensor.sensorName,
                  fill: false,
                  lineTension: 0,
                  borderColor: _color,
                  backgroundColor: _color,
                  borderWidth: 1,
                  pointRadius: 5,
                  pointHoverRadius: 8,
                  pointStyle: pointStyles,
                  images: images,
                  trainingLogIds: trainingLogIds,
                  pointName: pointName,
                  pointBackgroundColor: pointBackgroundColor,
                  graphValue: graphValue,
                  data: data,
                }),
              },
            };
          }
          _graphData?.data ? setGraphData([_graphData]) : setGraphData([]);
        } else {
          notify(messages.ToastError, messages.ServerNotResponding);
        }
      })
      .catch(function (error) {
        setSpinner(false);
      });
  };
  const getTrainingCombineLogs = () => {
    let sessionObj = sessions.find((inst) => inst.value === session);
    setSpinner(true);
    axios
      .post("/Training/getTrainingLogs", {
        trainingId: trainingLogId,
        macId: student,
        startDate: sessionObj?.startDate,
        endDate: sessionObj?.endDate,
      })
      .then(function (response) {
        setSpinner(false);
        // handle success
        let data = response.data ? response.data : {};
        if (!data.errorCode) {
          let sensors = data || [];

          let _graphData = [];
          for (let sensor of sensors) {
            let pointStyles = [],
              pointBackgroundColor = [],
              pointName = [],
              images = [],
              trainingLogIds = [],
              data = [],
              graphValue = [];
            let logData = sensor.logData || [];
            let y = 0;
            for (let log of logData) {
              if (log.action === messages.TrainingLogAction.sensorReadingSent) {
                pointStyles.push("");
                pointName.push("");
                pointBackgroundColor.push("white");
                images.push("");
                trainingLogIds.push("");
                graphValue.push("---");
                y = log.reading;
              } else if (log.action === messages.TrainingLogAction.tagAdded) {
                pointName.push("tag");
                if (log.image) {
                  pointStyles.push(camera);
                } else {
                  pointStyles.push("rect");
                }
                pointBackgroundColor.push("#90EE90");
                trainingLogIds.push(log.image || "");
                images.push(log.image || "");
                graphValue.push(log.tag);
              } else if (
                log.action === messages.TrainingLogAction.questionSent
              ) {
                pointName.push("asked");
                pointStyles.push("rect");
                pointBackgroundColor.push("blue");
                trainingLogIds.push("");
                images.push("");
                graphValue.push(log.question);
              } else if (
                log.action === messages.TrainingLogAction.alarm ||
                log.action === messages.TrainingLogAction.interAlarm
              ) {
                pointStyles.push("triangle");
                if (log.action === messages.TrainingLogAction.alarm) {
                  pointName.push("alarm");
                  pointBackgroundColor.push("red");
                } else {
                  pointName.push("interIntermediate-alarm");
                  pointBackgroundColor.push("#FFEB3BFF");
                }
                images.push("");
                trainingLogIds.push("");
                graphValue.push(log.question);
                y = log.reading;
              } else if (log.action === messages.TrainingLogAction.answerSent) {
                graphValue.push(log.givenAnswer);
                if (log.isGivenAnswerCorrect) {
                  pointName.push("correct");
                  pointStyles.push("rect");
                  images.push("");
                  trainingLogIds.push("");
                  pointBackgroundColor.push("green");
                } else {
                  pointName.push("incorrect");
                  pointStyles.push("rect");
                  images.push("");
                  trainingLogIds.push("");
                  pointBackgroundColor.push("red");
                }
              }
              data.push({ x: log.createdAt, y: y });
            }
            _graphData.push({
              data: {
                unit: sensor.sensorUnit || "",
                sensorLabel: sensor.sensorLabel || "",
                meterName: sensor.meterName || "",
                datasets: [
                  {
                    label: sensor.sensorName,
                    fill: false,
                    lineTension: 0,
                    borderColor: "rgba(0,0,0,1)",
                    borderWidth: 0.5,
                    pointRadius: 5,
                    pointHoverRadius: 8,
                    pointStyle: pointStyles,
                    images: images,
                    trainingLogIds: trainingLogIds,
                    pointName: pointName,
                    pointBackgroundColor: pointBackgroundColor,
                    graphValue: graphValue,
                    data: data,
                  },
                ],
              },
            });
          }
          setGraphData(_graphData);
        } else {
          notify(messages.ToastError, messages.ServerNotResponding);
        }
      })
      .catch(function (error) {
        setSpinner(false);
      });
  };

  const getTrainingSessions = () => {
    if (student) {
      setSpinner(true);
      axios
        .post("/Training/getTrainingSessions", {
          trainingId: trainingLogId,
          macId: student,
        })
        .then(function (response) {
          setSpinner(false);
          // handle success
          let data = response.data ? response.data : {};
          if (!data.errorCode) {
            let _sessions = data || [];
            if (_sessions) {
              let sessionList = [];
              for (let i in _sessions) {
                let item = {};
                item.value = i + 1;
                item.label =
                  toDefaultDateFormat(
                    _sessions[i].startDate,
                    messages.momentDateWithTime
                  ) +
                  " to " +
                  toDefaultDateFormat(
                    _sessions[i].endDate,
                    messages.momentDateWithTime
                  ) +
                  " - " +
                  _sessions[i].meterName || "";
                item.startDate = _sessions[i].startDate;
                item.endDate = _sessions[i].endDate;
                sessionList.push(item);
              }
              setSessions(sessionList);
              setSession("");
              setSession(sessionList[0].value);
            }
          } else {
            notify(messages.ToastError, messages.ServerNotResponding);
          }
        })
        .catch(function (error) {
          setSpinner(false);
        });
    }
  };

  const getTrainingDetails = () => {
    setSpinner(true);
    axios
      .post("/Training/getTrainingDetails", { id: trainingLogId })
      .then(function (response) {
        setSpinner(false);
        // handle success
        let data = response.data ? response.data : {};
        if (!data.errorCode) {
          // setTrainingId(data.id);
          setTrainingName(data.name);
          setInstructorName(data.instructorName || data.InstructorInfo.name || "---")
          setStartDate(data.createdAt);
          setEndDate(data.endedAt);

          /* student dropdown options*/
          let _students = data.students || [];
          if (_students.length) {
            let studentList = [];
            for (let i in _students) {
              let item = {};
              item.id = i;
              item.label = _students[i].name;
              item.email = _students[i].email;
              item._email = _students[i].email;
              item.value = _students[i].macId;
              studentList.push(item);
            }
            setStudent(studentList[0].value || "");
            setStudents(studentList);
          }
        } else {
          notify(messages.ToastError, messages.ServerNotResponding);
        }
      })
      .catch(function (error) {
        setSpinner(false);
      });
  };

  /* const graphClick = (data) => {
    setModelData(data);
    handleShow();
  }; */
  useEffect(() => {
    getTrainingSessions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student]);

  const graphHtml = graphData.length ? (
    graphData.map((inst, index) => {
      return (
        <Col lg={combineLogFlag ? 12 : 6} key={"graph" + index}>
          <div className="mt-3 text-center bold">{`${inst.data.datasets[0].label}`}</div>
          <Line
            style={{ maxHeight: "300px", maxWidth: "100%" }}
            data={inst.data}
            getElementsAtEvent={(dataset, event) => {
              event.stopPropagation();
              let _image =
                dataset?.[0] &&
                inst.data.datasets[dataset[0].datasetIndex].images[
                dataset[0].index
                ];
              let _trainingLogId =
                dataset?.[0] &&
                inst.data.datasets[dataset[0].datasetIndex].trainingLogIds[
                dataset[0].index
                ];
              setViewImage(_image);
              setTrainingLogId(_trainingLogId);
              if (dataset.length === 0 || !_image) {
                setModelData(inst.data);
                handleShow();
              } else {
                setModalShow(true);
                // window.open(messages.GraphImageUrl + _image, "_blank");
              }
            }}
            options={{
              responsive: true,
              cursor: "pointer",
              // onClick: () => {
              //   graphClick(inst.data);
              // },
              plugins: {
                legend: {
                  display: combineLogFlag ? true : false,
                  position: "bottom",
                  labels: {
                    usePointStyle: false,
                  },
                },
                zoom: {
                  pan: {
                    enabled: true,
                    mode: "xy",
                  },
                  zoom: {
                    wheel: {
                      enabled: true,
                    },
                    pinch: {
                      enabled: true,
                    },
                    mode: "xy",
                  },
                },
                tooltip: {
                  usePointStyle: true,
                  enabled: false,
                  external: function (context) {
                    // Tooltip Element
                    let tooltipEl = document.getElementById("chartjs-tooltip");

                    // Create element on first render
                    if (!tooltipEl) {
                      tooltipEl = document.createElement("div");
                      tooltipEl.id = "chartjs-tooltip";
                      tooltipEl.innerHTML = "<table></table>";
                      document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                      tooltipEl.style.opacity = 0;
                      // tooltipEl.style.display = "none"
                      return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove("above", "top", "no-transform");
                    if (tooltipModel.yAlign) {
                      tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                      tooltipEl.classList.add("no-transform");
                    }

                    // Set HTML & Data
                    if (tooltipModel.body) {
                      const {
                        label: title = "",
                        dataset: {
                          pointName = [],
                          graphValue = [],
                          images = [],
                        },
                        formattedValue: reading = "",
                        dataIndex,
                      } = tooltipModel.dataPoints[0];
                      let label = "",
                        label2 = graphValue[dataIndex] || "",
                        label3 = "",
                        iconClassName = "",
                        image = images[dataIndex] || "";
                      switch (pointName[dataIndex]) {
                        case "tag":
                          label = "Tag";
                          label3 =
                            (image &&
                              `<img src=${messages.GraphImageUrl + image
                              } alt="graph-image" class="graph-image" width="200px" height="150px"/>`) ||
                            "";
                          iconClassName = "square bg-light-green";
                          break;
                        case "asked":
                          label = "Question asked";
                          iconClassName = "square bg-blue";
                          break;
                        case "correct":
                          label = "Right answer";
                          iconClassName = "square bg-green";
                          break;
                        case "incorrect":
                          label = "Wrong answer";
                          iconClassName = "square bg-red";
                          break;
                        case "alarm":
                          label = "Alarm 2";
                          iconClassName = "triangle-up";
                          break;
                        case "interIntermediate-alarm":
                          label = "Alarm 1";
                          iconClassName = "triangle-up";
                          break;
                        case "cancelled":
                          label = "Cancelled";
                          break;
                        default:
                          label = "";
                      }
                      const innerHtml = `
                        <tr>
                          <td colspan="2"> ${title}</td> 
                        </tr>
                        <tr>
                          <td colspan="2">Reading : ${reading}</td>
                        </tr>
                        <tr>
                          <td colspan="2">
                          <span class="${iconClassName}"></span>
                          ${label}
                          </td>
                        </tr>
                        <tr>
                        <td  colspan="2"> ${label2} </td>
                        </tr>
                        <tr>
                        <td  colspan="2"> ${label3} </td>
                        </tr>
                    `;
                      // console.log(innerHtml);
                      tooltipEl.querySelector("table").innerHTML = innerHtml;
                    }
                    const position =
                      context.chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    tooltipEl.style.display = "";
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.background = "#fff";
                    tooltipEl.style.padding = "10px";
                    tooltipEl.style.color = "#000";
                    tooltipEl.style.position = "absolute";
                    tooltipEl.style.border = "1px solid #ccc";
                    tooltipEl.style.borderRadius = "8px";
                    tooltipEl.style.boxShadow =
                      "1px 1px 3px rgba(50,50,50,0.6)";
                    if (
                      position.left + window.pageXOffset + tooltipModel.caretX >
                      tooltipEl.parentElement.offsetWidth
                    ) {
                      tooltipEl.style.left = "";
                      tooltipEl.style.right = "0";
                    } else
                      tooltipEl.style.left =
                        position.left +
                        window.pageXOffset +
                        tooltipModel.caretX +
                        "px";
                    console.log(
                      position.left,
                      window.pageXOffset,
                      tooltipModel.caretX
                    );
                    tooltipEl.style.top =
                      position.top +
                      window.pageYOffset +
                      tooltipModel.caretY +
                      "px";
                    tooltipEl.style.padding =
                      tooltipModel.padding +
                      "px " +
                      tooltipModel.padding +
                      "px";
                    tooltipEl.style.pointerEvents = "none";
                    // console.log(tooltipEl.style);
                  },
                },
                title: {
                  display: false,
                  text: "",
                  fontSize: 20,
                },
              },
              scales: {
                yAxes: {
                  title: {
                    display: true,
                    text: inst.data?.unit || "",
                    font: {
                      size: 15,
                    },
                  },
                  ticks: {
                    precision: 0,
                  },
                },
                x: {
                  type: "time",
                  time: {
                    unit: "minute",
                  },
                  title: {
                    display: true,
                    text: "Time",
                    font: {
                      size: 15,
                    },
                  },
                  // bounds:'data'
                },
              },
            }}
          />
        </Col>
      );
    })
  ) : (
    <Col lg="12">
      <div className="text-center bold mt-5">No chart found</div>
    </Col>
  );
  /* function openManual() {
     setSpinner(true);
     axios
       .post("/Training/generateTrainingReport", {
         trainingId: trainingLogId,
         macId: student,
         combinedView: combineLogFlag,
         graphImgShow: graphImgShow
       })
       .then(function (response) {
         setSpinner(false);
         // handle success
         let data = response.data ? response.data : {};
         if (!data.errorCode) {
           let trainingReportLink = messages.WebUrl + "/training-reports/" + data.pdf;
           window.open(trainingReportLink);
         } else {
           notify(messages.ToastError, messages.ServerNotResponding);
         }
       })
       .catch(function (error) { setSpinner(false) });
   }*/
  return (
    <>
      <Container fluid className="px-0 body-component">
        {spinner ? (
          <Spinner />
        ) : (
          <div className="container-fluid mt-3">
            {show ? (
              <Graph
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                show={show}
                data={modalData}
                combineLogFlag={combineLogFlag}
              />
            ) : (
              ""
            )}
            {showStudents ? (
              <DownloadStudentsPdf
                show={showStudents}
                getTrainingDetails={getTrainingDetails}
                onHide={() => setShowStudents(false)}
                allStudent={students}
                setSpinner={setSpinner}
                trainingId={trainingLogId}
                combineLogFlag={combineLogFlag}
                graphImgShow={graphImgShow}
                student={student}
                sharing={sharing}
              />
            ) : (
              ""
            )}
            {modalShow && <ActionDeletePopup
              handleClose={handleCloseDelete}
              handleShow={handleShowDelete}
              setShow={setShow}
              show={modalShow}
              viewImage={viewImage}
              message={modalShow && messages.SelectAction}
              action={modalShow && messages.DeleteImage}
              deactivateFunction={() => DeleteImage()}
            />}
            <div className="row">
              <div className="d-flex justify-content-between">
                <p className="font-bold mb-1">{messages.LogViewer}</p>
                {/*<button className="ml-2 combineLogButton" onClick={() => setCombineLogFlag(!combineLogFlag)}>
                {combineLogFlag === false ? "Combined" : "Different"}
      </button>*/}
                <div>
                  <span className="me-3 bold">{messages.combinedView} :</span>
                  <label className="combineSwitch">
                    <input
                      type="checkbox"
                      onChange={(e) => setCombineLogFlag(!combineLogFlag)}
                      checked={combineLogFlag}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span className="ms-3 me-3 bold">
                    {messages.includeGraphInReport} :
                  </span>
                  <label className="combineSwitch">
                    <input
                      type="checkbox"
                      onChange={(e) => setGraphImgShow(!graphImgShow)}
                      checked={graphImgShow}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            <hr />
            <div className="container-fluid grid-list-div mb-3">
              <div className="training-log-filter py-4">
                <Row>
                  {/* <Col lg={4}>
                  <div className="mt-2">
                    <span className="font-bold">{messages.TrainingId}: </span>
                    <span>{trainingId}</span>
                  </div>
                </Col> */}
                  <Col lg={3}>
                    <div className="mt-2 ellipsis-text">
                      <span className="font-bold">
                        {messages.TrainingName}:{" "}
                      </span>
                      <span className="">{trainingName}</span>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="ml-3 mt-2">
                      <span className="font-bold">{messages.StartDate}: </span>
                      <span>
                        {startDate
                          ? toDefaultDateFormat(
                            startDate,
                            messages.momentDateWithTime
                          )
                          : ""}
                      </span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="ml-3 mt-2">
                      <span className="font-bold">
                        &nbsp;&nbsp;{messages.Duration}:{" "}
                      </span>
                      <span>{durationCalculate(startDate, endDate)}</span>
                    </div>
                  </Col>
                  <Col lg={1}>
                    <div
                      className="exportIconDiv pointer"
                      onClick={() => {
                        setSharing(true);
                        setShowStudents(true);
                      }}
                      title="Share report"
                    >
                      <i className="fas fa-share-square"></i>
                    </div>
                    <div className="bottom-export">{messages.Report}</div>
                  </Col>
                  <Col lg={1}>
                    <div
                      className="exportIconDiv pointer"
                      onClick={() => {
                        setSharing(false)
                        setShowStudents(true);
                      }}
                      title="Download report"
                    >
                      <i className="fa fa-download" aria-hidden="true"></i>
                    </div>
                    <div className="bottom-export">{messages.Report}</div>
                  </Col>
                  <Col lg={3}>
                    <div className="mt-4 ellipsis-text">
                      <span className="font-bold">
                        {messages.InstructorName}:{" "}
                      </span>
                      <span className="">{instructorName}</span>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="d-flex students-select mt-3">
                      <span className="font-bold mt-2">
                        {messages.Student}:&nbsp;&nbsp;
                      </span>
                      <Form.Select
                        aria-label="Default select student"
                        value={student}
                        onChange={(e) => setStudent(e.target.value)}
                      >
                        {students.map((e, key) => {
                          return (
                            <option key={"student-" + key} value={e.value}>
                              {e.label}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="d-flex mt-3" style={{ marginLeft: "0.5rem" }}>
                      <span className="font-bold mt-2">
                        {messages.Session}:&nbsp;&nbsp;
                      </span>
                      <Form.Group className="mb-3" controlId="session">
                        <Form.Select
                          aria-label="Default select session"
                          value={session}
                          onChange={(e) => setSession(e.target.value)}
                        >
                          {sessions.map((e, key) => {
                            return (
                              <option key={"session-" + key} value={e.value}>
                                {e.label}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-5">{graphHtml}</Row>
                <Row>
                  <div
                    id="chartjs-tooltip"
                    style={{
                      width: "220px",
                      background: "red",
                      opacity: "1 !important",
                    }}
                  >
                    <table></table>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};
export default TrainingLogViewer;
