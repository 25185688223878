import { Col, Row } from "react-bootstrap";
import { messages } from "../../../../utils/constants";

const PreviewNotAvailable = () => {
    return (
        <Row>
            <Col lg={12}>
                <div className="Heading-for-modal text-center">
                    <h1>{messages.meterPreviewDefaults.noSkin}</h1>
                </div>
            </Col>
        </Row>
    );
}
export default PreviewNotAvailable;