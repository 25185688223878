// import React from 'react'
import { toast } from "react-toastify";
import { messages } from "./constants";
import validator from "validator";
import axios from "axios";
import moment from "moment";
import ReactDOM from "react-dom";
const invalidDate = "Invalid Date";
let toastId;
//FUNCTION FOR STRING ENCODE :START
export function encodeString(data) {
  data = escape(encodeURIComponent(data));
  return data;
}
//FUNCTION FOR STRING ENCODE : END

/*FUNCTION FOR STRING DECODE :START */
export function decodeString(data) {
  data = decodeURIComponent(data);
  return data;
}
/*FUNCTION FOR STRING DECODE :END */
/*SHOWING NOTIFY MESSAGES: START */
function closeToast() {
  toastId = null;
}
export function notify(notifyType, constants, autoClose) {
  autoClose = !!autoClose;
  if (toastId) {
    if (notifyType === "error")
      toast.update(toastId, {
        render: constants,
        type: toast.TYPE.ERROR,
        onClose: closeToast,
      });
    else if (notifyType === "warning")
      toast.update(toastId, {
        render: constants,
        type: toast.TYPE.WARNING,
        onClose: closeToast,
      });
    else
      toast.update(toastId, {
        render: constants,
        type: toast.TYPE.SUCCESS,
        onClose: closeToast,
        autoClose: 5000,
      });
  } else {
    if (notifyType === "error") {
      if (autoClose)
        toastId = toast.error(constants, {
          onClose: closeToast,
          autoClose: 5000,
        });
      else toastId = toast.error(constants, { onClose: closeToast });
    } else if (notifyType === "warning")
      toastId = toast.warn(constants, { onClose: closeToast, autoClose: 5000 });
    else
      toastId = toast.success(constants, {
        onClose: closeToast,
        autoClose: 5000,
      });
  }
}
/*SHOWING NOTIFY MESSAGES: END */
export function showLoader() {
  document.getElementById("parent-loader").style.display = "block";
  document.querySelector("body").style.overflow = "hidden";
}
export function hideLoader() {
  document.getElementById("parent-loader").style.display = "none";
  document.querySelector("body").style.overflow = "auto";
}
export function focusById(id) {
  let x = document.getElementById(id.toString());
  if (x) {
    x.focus();
    return true;
  }
  return false;
}
export function isMatches(value, regex) {
  if (typeof value === "number" && !isNaN(value)) value = value.toString();
  value = value ? value.toString() : "";
  value = value.trim();
  if (!value || !value.match(regex)) {
    return false;
  }
  return true;
}
export function isEmail(value) {
  value = value ? value.toString() : "";
  value = value.trim();
  if (!validator.isEmail(value)) {
    return false;
  }
  return true;
}
export function toDefaultDateFormat(date, customDateFormat) {
  if (
    date &&
    !isNaN(parseInt(date)) &&
    date.toString() === parseInt(date).toString()
  ) {
    date = parseInt(date);
  }
  if (date && new Date(date).toString() !== "Invalid Date") {
    // follow this link for moment documentation to format date
    // https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
    date = new Date(date);
    let dateFormat = customDateFormat
      ? customDateFormat
      : messages.momentDateFormat;
    return moment(date).format(dateFormat);
  } else {
    return "Invalid Date";
  }
}
/*Upload file- start*/
export function uploadFile(dataToSend, successCallback, errorMsg) {
  axios
    .post("/uploadFile/upload", dataToSend)
    .then(function (response) {
      let data = response.data ? response.data : {};
      if (data.uploadSuccess) {
        successCallback(data.fileName);
      } else {
        notify(messages.toastError, "error");
      }
    })
    .catch(function (response) {});
}
export function genRandomColor() {
  var result = "#";
  var characters = "0123456789ABCDEF";
  var charactersLength = characters.length;
  for (var i = 0; i < 6; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

//FUNCTION FOR DURATION CALCULATION :START

export const durationCalculate = (startDate, endDate) => {
  let momentStartDate = moment(startDate);
  let momentEndDate = moment(endDate);
  let differenceHours = momentEndDate.diff(momentStartDate, "hours");
  let differenceMinutes =
    momentEndDate.diff(momentStartDate, "minutes") - 60 * differenceHours;
  let roundOffMins = 0;
  if (differenceMinutes >= 7.5 && differenceMinutes < 22.5) {
    roundOffMins = 0.25;
  } else if (differenceMinutes >= 22.5 && differenceMinutes < 37.5) {
    roundOffMins = 0.5;
  } else if (differenceMinutes >= 37.5 && differenceMinutes < 52.5) {
    roundOffMins = 0.75;
  } else if (differenceMinutes >= 52.5) {
    differenceHours++;
  }
  let finalDuration = differenceHours + roundOffMins;
  return finalDuration;
};
//FUNCTION FOR DURATION CALCULATION :END

/**
 * @returns {arr[][]}
 */
export const chunk = (arr, size) => {
  if (!Array.isArray(arr)) return [];
  if (isNaN(size)) size = arr.length;
  const result = [];
  // loop through the original array
  for (let i = 0; i < arr.length; i += size) {
    // get a slice of the original array of size 'size'
    const chunk = arr.slice(i, i + size);
    // push the chunk to the result array
    result.push(chunk);
  }
  return result;
};

export const getRandomReadings = (sensor, alarmRandomization = false) => {
  let min,
    max,
    diff,
    alarm = "",
    range,
    units = sensor?.sensorData?.sensorUnit || sensor?.units || [];
  if (sensor.edit || sensor.new) {
    range = {
      sensorPrecision: +sensor.sensorData.sensorPrecision || 0,
      sensorNormVal: +sensor.sensorData.sensorNormVal || 0,
      sensorMinVal: +sensor.sensorData.sensorMinVal || 0,
      sensorInterVal: +sensor.sensorData.sensorInterVal || 0,
      sensorLowAlarms: +sensor.sensorData.sensorLowAlarms || 0,
      sensorLowLowAlarms: +sensor.sensorData.sensorLowLowAlarms || 0,
      sensorAlarm2Val: +sensor.sensorData.sensorAlarm2Val || 0,
      sensorMaxVal: +sensor.sensorData.sensorMaxVal || 0,
    };
  } else {
    range = {
      sensorPrecision: +sensor.precision || 0,
      sensorNormVal: +sensor.normValue || 0,
      sensorMinVal: +sensor.minValue || 0,
      sensorInterVal: +sensor.interAlarmValue || 0,
      sensorLowAlarms: +sensor.sensorLowAlarms || 0,
      sensorLowLowAlarms: +sensor.sensorLowLowAlarms || 0,
      sensorAlarm2Val: +sensor.alarm2 || 0,
      sensorMaxVal: +sensor.maxValue || 0,
    };
  }
  if (alarmRandomization) {
    min = range.sensorMinVal;
    max = range.sensorMaxVal;
  } else {
    min = Math.max(
      range.sensorMinVal,
      range.sensorLowAlarms,
      range.sensorLowLowAlarms
    );
    max =
      Math.min(
        range.sensorInterVal,
        range.sensorAlarm2Val,
        range.sensorMaxVal
      ) || range.sensorAlarm2Val;
  } /*  else if (range.sensorInterVal) {
        min = range.sensorInterVal;
        max = range.sensorMaxVal;
    } else {
        min = range.sensorAlarm2Val;
        max = range.sensorMaxVal;
    } */
  diff = max - min;
  let reading = +(min + Math.random() * diff)?.toFixed?.(
      range.sensorPrecision || 0
    ),
    val = +reading,
    unitIndex = 0,
    unitsLength = units?.length || 0;
  if (!alarmRandomization) {
    let _reading = (
      +sensor?.sensorData?.sensorNormVal ||
      +sensor?.normValue ||
      0
    )?.toFixed?.(range.sensorPrecision || 0);
    let val = +_reading;
    while (val >= 1000 && unitIndex < unitsLength - 1) {
      val /= 1000;
      unitIndex++;
    }
    return {
      reading: _reading,
      alarm,
      units: [units?.[unitIndex] || { label: "--" }],
      sensorLabel: sensor?.sensorData?.sensorLabel || sensor?.sensorLabel || "",
      range,
    };
  }
  if (
    range.sensorLowLowAlarms &&
    val >= range.sensorMinVal &&
    val <= range.sensorLowLowAlarms
  )
    alarm = "a2";
  else if (
    range.sensorLowAlarms &&
    val >= range.sensorMinVal &&
    val <= range.sensorLowAlarms
  )
    alarm = "a1";
  else if (
    range.sensorInterVal &&
    val >= range.sensorInterVal &&
    val < range.sensorAlarm2Val
  )
    alarm = "a1";
  else if (val >= range.sensorAlarm2Val) alarm = "a2";
  while (val >= 1000 && unitIndex < unitsLength - 1) {
    val /= 1000;
    unitIndex++;
  }
  reading = val?.toFixed?.(range.sensorPrecision || 0);
  return {
    reading,
    alarm,
    units: [units?.[unitIndex] || { label: "--" }],
    sensorLabel: sensor?.sensorData?.sensorLabel || sensor?.sensorLabel || "",
    range,
  };
};

export const isObjectId = (val) => {
  return !!val?.toString?.()?.match?.(/^[\da-f]{24}$/gi);
};

export const getBarColor = (index, barValue, range) => {
  const i = index + 1;
  let val;
  if (i <= barValue) val = i;
  else return "bg-white"; // empty bar
  if (
    range.sensorLowLowAlarms &&
    val >= range.sensorMinVal &&
    val <= range.sensorLowLowAlarms
  )
    return "bg-green"; // low low alarm
  else if (
    range.sensorLowAlarms &&
    val >= range.sensorMinVal &&
    val <= range.sensorLowAlarms
  )
    return "bg-green"; // low alarm
  else if (
    range.sensorInterVal &&
    val >= range.sensorInterVal &&
    val < range.sensorAlarm2Val
  )
    return "bg-yellow"; // alarm 1
  else if (val >= range.sensorAlarm2Val) return "bg-red"; // alarm 2
  else return "bg-green"; // normal
};

export const openInNewTab = (url) => {
  let _url = new URL(url);
  let a = document.createElement("a");
  a.setAttribute("href", _url.toString());
  a.setAttribute("target", "_blank");
  a.click();
};

export const downloadJson = async (jsonData, fileName) => {
  if (jsonData) {
    const jsonBlob = new Blob([JSON.stringify(jsonData, null, 2)], {
      type: "application/json",
    });
    const url = window.URL.createObjectURL(jsonBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName || "downloaded"}.json`;
    // document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    return true;
  }
  throw new Error("Not a valid object");
};

/**
 * Converts a value to a number or returns null (or a provided default value) if the value cannot be converted to a number.
 *
 * @function toNumberOrNull
 * @param {any} value - The value to convert to a number.
 * @param {number|null} [defaultValue=null] - The default value to return if the conversion fails. Defaults to null.
 * @returns {number|null} - The numeric representation of the value, or null (or the provided default) if the conversion fails.
 *
 * @example
 * const inputValue = "42";
 * const result = toNumberOrNull(inputValue, null); // Returns the number 42
 *
 * const invalidValue = "not a number";
 * const defaultResult = toNumberOrNull(invalidValue, 0); // Returns the default value 0
 *
 * const noDefault = "not a number";
 * const noDefaultResult = toNumberOrNull(noDefault); // Returns null (default not provided)
 */
export const toNumberOrNull = (value, defaultValue = null) => {
  const numericValue = parseFloat(value);
  if (!isNaN(numericValue) && !isNaN(+value) && numericValue === +value)
    return numericValue;
  return defaultValue;
};

/**
 * Checks if a value is either null, undefined, an empty string, or can be converted to a numeric value.
 *
 * @param {any} value - The value to be checked.
 * @returns {boolean} True if the value is null, undefined, an empty string, or can be converted to a numeric value; otherwise, false.
 *
 * @example
 * // Example 1: Empty string should return true
 * const result1 = ifIsNumberOrNUll("");
 * console.log(result1); // Output: true
 *
 * // Example 2: Null value should return true
 * const result2 = ifIsNumberOrNUll(null);
 * console.log(result2); // Output: true
 *
 * // Example 3: Undefined value should return true
 * const result3 = ifIsNumberOrNUll(undefined);
 * console.log(result3); // Output: true
 *
 * // Example 4: A valid number (numeric string) should return true
 * const result4 = ifIsNumberOrNUll("42.5");
 * console.log(result4); // Output: true
 *
 * // Example 5: A non-numeric string should return false
 * const result5 = ifIsNumberOrNUll("Hello");
 * console.log(result5); // Output: false
 */
export const ifIsNumberOrNUll = (value) => {
  if ([undefined, null, ""].includes(value)) return true;
  return toNumberOrNull(value) !== null;
};

export const openComponentPopupWindow = (
  props = {},
  { title = "Popup Window", Component = (props) => "" }
) => {
  const popupWindow = window.open("", title, "width=600,height=400");

  // Pass data to the popup window
  popupWindow.dataFromParent = props;

  // Load the HTML file containing the React app in the popup window
  popupWindow.document.open();
  popupWindow.document.write('<div id="popup-root"></div>');
  popupWindow.document.close();
  let elem = popupWindow.document.getElementById("popup-root");
  popupWindow.addEventListener("close", () => {
    ReactDOM.unmountComponentAtNode(elem);
  });

  ReactDOM.render(<Component data={props} />, elem);
};

/**
 * Convert a number or a string to a JavaScript Date object.
 *
 * @param {number|string} input - The input value to be converted to a Date object.
 *   If it's a number, it is treated as a timestamp (milliseconds since the Unix epoch).
 *   If it's a string, it should be a valid date format that JavaScript can parse.
 * @returns {Date|null} - The Date object representing the input value, or null if the input is not valid.
 */
export const convertToDateTime = (input) => {
  if (typeof input === "number") {
    return new Date(input);
  } else if (typeof input === "string") {
    const date = new Date(input);
    if (!isNaN(date.getTime())) {
      return date;
    }
  }
  return null;
};
