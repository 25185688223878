import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Gadgets from "../../images/gadgets.png";
import "../../style/style.css";
import Logo from "../../images/logo.png";
// import { useHistory } from "react-router-dom";
import { useInputHook } from "../custom/custom-input-hook";
import ForgetPassword from "../forget-password/forget-password";
import Spinner from "../spinner/spinner";
import { messages } from "../utils/constants";
import axios from "axios";
import { notify } from "../utils/utility";
import { ReactTitle } from "react-meta-tags";
const Login = () => {
  const [password, setPassword] = useInputHook();
  const [email, setEmail] = useInputHook();
  const [emailErr, setEmailErr] = useState({});
  const [passwordErr, setPasswordErr] = useState({});

  const [spinner, setSpinner] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [emailType, setEmailType] = useState("password");

  const handleShow = () => {
    setShow(true);
  };

  // const history = useHistory();
  const setOpen = () => {
    handleShow();
  };

  const submitHandler = (e) => {
    e.preventDefault(e);

    const isValid = formValidation();
    var data = { email, password };
    if (isValid) {
      setSpinner(true);
      setTimeout(function () {
        setSpinner(false);
      }, 2000);
      axios
        .post("/signin", data)
        .then((res) => {
          localStorage.setItem("loggedInUserName", res.data.user.name);
          localStorage.setItem("userId", res.data.user.id);
          setSpinner(false);
          if (res.data.errorCode) {
            notify(messages.ToastError, messages.invalidEmailPassword);
          } else {
            setSpinner(false);
            localStorage.setItem(
              "organizationId",
              res.data.user.organizationId
            );
            let role = res.data.user.role || 4;
            localStorage.setItem("role", res?.data?.user?.role);
            localStorage.setItem("email", res?.data?.user?.email);
            localStorage.setItem(
              "organizationName",
              res?.data?.user?.organizationDetails?.name
            );
            localStorage.setItem(
              "profileSrc",
              "/upload/userProfile/" + res.data.user.profileSrc.serverName
            );
            localStorage.setItem(
              "meterDisabledForOrg",
              res?.data?.user?.organizationDetails?.meterDisabledForOrg ===
                false
                ? false
                : true
            );
            //window.fetchLatestProfile()
            let redirectPath =
              role === 1
                ? "/Home/Organizations"
                : role === 2
                ? "/Home/User"
                : "/Home/Trainings";
            window.location.href = redirectPath;
            //auth.Login(() => {
            // history.push("/Home");
            //});
          }
        })
        .catch((error) => {
          console.log(error);
          notify(messages.ToastError, "Login failed");
          setSpinner(false);
        });
    } else {
      notify(messages.ToastError, messages.invalidEmailPassword);
    }
  };

  const formValidation = () => {
    const emailErr = {};
    const passwordErr = {};
    let isValid = true;
    if (email.trim().length < 6) {
      emailErr.emailShort = messages.invalidEmailPassword;
      isValid = false;
    }
    if (password.trim().length < 6) {
      passwordErr.passwordShort = messages.invalidEmailPassword;
      isValid = false;
    }
    setEmailErr(emailErr);
    setPasswordErr(passwordErr);
    return isValid;
  };
  const showPassword = () => {
    setEmailType(emailType === "password" ? "text" : "password");
  };
  return (
    <Row className="main-root">
      <ReactTitle title="Hazsim-Login" />
      <Col lg={7} className="p-0">
        <div className="login-left-side">
          <div className="login-left-side-img"></div>
        </div>
      </Col>
      <Col lg={5} className="p-0">
        <div className="right-wrapper">
          <div className="right-wrapper-content">
            {spinner ? <Spinner /> : ""}
            <Row className="logo m-auto">
              <img src={Logo} alt="logo" />
            </Row>
            <form className="m-5" onSubmit={submitHandler}>
              <div className="mb-3">
                <input
                  className="input-basic mb-0"
                  placeholder={messages.EMAIL}
                  value={email}
                  onChange={setEmail}
                  disabled={spinner && true}
                />
              </div>

              <div className="mb-3">
                <div className="input-group ">
                  <input
                    className="input-basic password-input mb-0"
                    type={emailType}
                    placeholder={messages.PASSWORD}
                    value={password}
                    onChange={setPassword}
                    disabled={spinner && true}
                    id="id_password"
                  />
                  <div className="password-eye">
                    <i
                      className="far fa-eye"
                      id="togglePassword"
                      onClick={showPassword}
                    ></i>
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex justify-content-end">
                <button
                  className={`justify-content-left submit-single-btn pointer ${!(
                    email && password
                  )}`}
                  variant="primary"
                  disabled={(!(email && password) || spinner) && true}
                >
                  {messages.LOGIN}{" "}
                  <i
                    className={`fas fa-sign-out-alt ml-1 pointer ${!(
                      email && password
                    )}`}
                  ></i>
                </button>
              </div>
            </form>
            <div>
              <div className="w-100 px-5">
                <div className="color-class" onClick={setOpen}>
                  {messages.ForgotPassword}
                </div>
                <br />
                <div href="#" className="colorClass2 mt-2">
                  <span className="color-class-black font-bold">
                    {messages.DONT_HAVE_AN_ACCOUNT}{" "}
                  </span>
                  <Link to="/ContactUs" className="color-class">
                    {" "}
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
      {show ? (
        <ForgetPassword
          handleClose={handleClose}
          handleShow={handleShow}
          setShow={setShow}
          show={show}
        />
      ) : (
        ""
      )}
    </Row>
  );
};
export default Login;
