import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Row } from "react-bootstrap";
import HeaderComponent from "../headerComponent/header-component";
import Footer from "../footer/footer";
import BodyComponent from "./body-component";
import SuperAdminBody from "./super-admin-body";
import Agreements from "./popup/aggrements";
import axios from "axios";
import io from "socket.io-client";
import { convertToDateTime } from "../utils/utility";
import { messages } from "../utils/constants";
const MainWrapper = () => {
  const [state, setState] = useState({ width: "fullyOpened" });
  const [aggrementModelShow, setAgreementModelShow] = useState(false);
  const [agreementId, setAgreementId] = useState("");
  const [spinner, setSpinner] = useState(false);
  const role = useMemo(() => localStorage.getItem("role"), []);
  const socket = useMemo(() => io(messages.socketURL), []);

  const data = [
    {
      organization: "ABC safty",
      contact: "Jhon Doe",
      Email: "demo@gmail.com",
      mobileNo: "0123456789",
      expiryDate: "11/10/2021",
    },
    {
      organization: "ABC safty",
      contact: "Jhon Doe",
      Email: "demo@gmail.com",
      mobileNo: "0123456789",
      expiryDate: "11/10/2021",
    },
    {
      organization: "ABC safty",
      contact: "Jhon Doe",
      Email: "demo@gmail.com",
      mobileNo: "0123456789",
      expiryDate: "11/10/2021",
    },
  ];

  useEffect(() => {
    socket.on("agreementPublished", () => {
      getUserAgreement();
    });
    getUserAgreement();
    return () => {
      socket.close();
    };
  }, []);
  const getUserAgreement = useCallback(() => {
    if (parseInt(role) === 1) return;
    setSpinner(true);
    const url = "/AcceptAgreements/getUserAgreement";
    axios
      .get(url)
      .then(function (response) {
        if (response.data.success) {
          let upcomingAgreementEffDate = convertToDateTime(
            response?.data?.upcomingAgreementEffDate
          );
          if (upcomingAgreementEffDate) {
            let date = new Date();
            if (
              upcomingAgreementEffDate - date <
              24 /* Hours */ *
                60 /* Minutes */ *
                60 /* Seconds */ *
                1000 /* ms */
            ) {
              setTimeout(getUserAgreement, upcomingAgreementEffDate - date);
            }
          }
          setSpinner(false);
          setAgreementModelShow(response.data.showPopup);
          setAgreementId(response.data.agreementId);
        }
      })
      .catch((error) => {
        console.error(error);
        setSpinner(false);
      });
  }, []);

  return (
    <>
      <Container fluid>
        {(parseInt(role) === 2 || parseInt(role) === 3) &&
          aggrementModelShow && (
            <Agreements
              show={aggrementModelShow}
              setShow={setAgreementModelShow}
              agreementId={agreementId}
            />
          )}
        <Row>
          <HeaderComponent state={state} setState={setState} />
          <BodyComponent
            SuperAdminBody={<SuperAdminBody state={state} data={data} />}
          />
          <Footer />
        </Row>
      </Container>
    </>
  );
};
export { MainWrapper };
