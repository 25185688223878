import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Container } from 'react-bootstrap'
import { messages } from "../../utils/constants";
import axios from 'axios'
import { useHistory } from "react-router-dom";
import Select from 'react-select'
import { notify, hideLoader, showLoader, isMatches, focusById, isEmail, toDefaultDateFormat, encodeString } from '../../utils/utility'
const _ = require('lodash');



const SupportContactInfo = () => {
    const loggedInUserOrganizationId = localStorage.getItem("organizationId")
    const history = useHistory();
    const role = localStorage.getItem("role");
    const invalidData = {
        email: false,
        mobile: false,
        office: false,
        street: false,
        state: false,
        zipCode: false
    }
    const [invalid, setInvalid] = useState(invalidData);
    const [action, setAction] = useState("edit");
    const [email, setEmail] = useState();
    const [mobile, setMobile] = useState();
    const [office, setOffice] = useState();
    const [street, setStreet] = useState();
    const [country, setCountry] = useState({});
    const [state, setState] = useState({});
    const [city, setCity] = useState({});
    const [zipCode, setZipCode] = useState();
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [countryCodeList, setCountryCodeList] = useState([]);
    const [savedState, setSavedState] = useState();
    const [savedCity, setSavedCity] = useState();

    useEffect(() => {
        if (loggedInUserOrganizationId) {
            getSupportContactData();
        } else {
            getCountryList()
        }
    }, [])


    const handleSelectCountry = (event) => {
        setCountry({ value: event.target.value, name: event.target.options[event.target.selectedIndex].text });
    }
    const handleSelectState = (event) => {
        setState({ value: event.target.value, name: event.target.options[event.target.selectedIndex].text });
    }
    const handleSelectCity = (event) => {
        setCity({ value: event.target.value, name: event.target.options[event.target.selectedIndex].text });
    }

    useEffect(() => {
        setState("")
        setSavedState("")
        if (country)
            getStateList()
    }, [country])
    useEffect(() => {
        setCity("")
        setSavedCity("")
        if (state)
            getCityList()
    }, [state])

    const getSupportContactData = () => {
        const url = '/Organizations/hazsimSupportContactInfo';
        axios.post(url).then((res) => {
            if (res) {
                const organizationData = res.data
                if (organizationData) {
                    setAction("view");
                    setStreet(organizationData.street)
                    setCountry(organizationData.country)
                    setState(organizationData.state)
                    setCity(organizationData.city)
                    setZipCode(organizationData.zipCode)
                    setEmail(organizationData.contactEmail)
                    setMobile(organizationData.contactMobile)
                    setOffice(organizationData.contactOffice)
                    getCountryList(organizationData.country);
                    setSavedState(organizationData.state);
                    setSavedCity(organizationData.city);
                }
            }
        }).catch((error) => {
            console.log(error);
        })
    }



    const getCountryList = (savedCountry) => {
        axios.post("/getCountryList", {
            //accessToken: accessToken
        }).then((response) => {
            // handle success
            let data = response.data ? response.data : {};
            if (data.errorCode) {
                notify(messages.ToastError, "server issue");
            }
            else {
                let countryCodeList = []
                for (let i in data) {
                    let code = {
                        id: i,
                        label: data[i].phone_code,
                        value: data[i].phone_code
                    }
                    countryCodeList.push(code)
                    data[i].label = data[i].name;
                    data[i].value = data[i].uniqueId;
                }
                setCountryCodeList(countryCodeList)
                setCountryList(data)
                /*country*/
                if (savedCountry) {
                    let selectCountry = {};
                    selectCountry = _.find(data, function (inst) {
                        return inst["label"] === savedCountry
                    }.bind(this));
                    setCountry(selectCountry);
                } else {
                    setCountry({ value: data[0].value, name: data[0].name })
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    const getStateList = () => {
        axios.post('/getStateList', { countryUniqueId: country.value })
            .then(function (response) {
                // handle success
                let data = response.data ? response.data : {};
                if (data.errorCode) {
                    notify(messages.ToastError, "server issue");
                }
                else {
                    for (let i in data) {
                        data[i].label = data[i].name;
                        data[i].value = data[i].uniqueId;
                    }
                    setStateList(data)
                    /*state*/
                    if (savedState) {
                        let selectState = {};
                        selectState = _.find(data, function (inst) {
                            return inst["label"] === savedState
                        }.bind(this));
                        setState(selectState);
                    } else {
                        setState({ value: data[0].value, name: data[0].name })
                    }
                }
            })
            .catch(function (error) {
            })
    }
    const getCityList = () => {
        axios.post('/getCityList', { stateUniqueId: state.value })
            .then(function (response) {
                // handle success
                let data = response.data ? response.data : {};
                if (data.errorCode) {
                    notify(messages.ToastError, "server issue");
                }
                else {
                    for (let i in data) {
                        data[i].label = data[i].name;
                        data[i].value = data[i].uniqueId;
                        data[i].latitude = data[i].latitude;
                        data[i].longitude = data[i].longitude;
                    }
                    setCityList(data)
                    /*city*/
                    if (savedCity) {
                        let selectCity = {};
                        selectCity = _.find(data, function (inst) {
                            return inst["label"] === savedCity
                        }.bind(this));
                        setCity(selectCity);
                    } else {
                        setCity({ value: data[0].value, name: data[0].name })
                    }
                }
            })
            .catch(function (error) {
            })
    }


    const submitHandler = (e) => {
        e.preventDefault(e);
        let isValid = validator();
        var data = { id: loggedInUserOrganizationId, actionType: action, email, mobileNumber: mobile, officePhone: office, street, countryS: country.name, stateS: state.name, cityS: city.name, zipCode };
        if (isValid) {
            axios.post("/Organizations/addUpdateOrganizations", data)
                .then((res) => {
                    if (res.data.errorCode) {
                        notify(messages.ToastError, messages.contactError);
                    } else {
                        notify(messages.ToastSuccess, messages.supportContactInfoUpdated);
                    }
                })
                .catch((error) => {
                    console.log("error")
                });
        }
    };

    const validator = () => {
        let invalid = {
            email: false,
            mobile: false,
            office: false,
            street: false,
            zipCode: false
        };
        var flag = false;
        /*Validations*/
        if (!isEmail(email)) {
            flag = invalid.Email = true;
            focusById('Email');
            notify(messages.ToastError, messages.InvalidEmail);
        } else if (!isMatches(mobile, messages.RegexAllowForPhoneNumber)) {
            flag = invalid.message = true;
            focusById('mobile');
            notify(messages.ToastError, messages.InvalidMobileNumber);
        }
        else if (!isMatches(office, messages.RegexAllowForPhoneNumber)) {
            flag = invalid.office = true;
            focusById('office');
            notify(messages.ToastError, messages.InvalidPhoneNumber);
        }
        else if (!isMatches(street, messages.RegexAlphaNumericSpecialChar)) {
            flag = invalid.street = true;
            focusById('street');
            notify(messages.ToastError, messages.InvalidStreetName);
        }
        else if (!isMatches(zipCode, messages.RegexAlphaNumericSpecialChar)) {
            flag = invalid.zipCode = true;
            focusById('zipCode');
            notify(messages.ToastError, messages.InvalidZipCode);
        }
        setInvalid(invalid)
        return flag ? false : true;
        //return;
    }

    let disableBtn = !email || !mobile || !office || !street || !country || !city || !state || !zipCode;
    return (
        <>
            <Container className="manuals-root">
                <Row>
                    <h6 className="text-center manual-heading">Support Contact Info</h6>
                </Row>
                <hr />
                <Row className="mt-4 support-contact-form">
                    <Col lg={6}>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>{messages.EmailTitleCase}*</Form.Label>

                            {(parseInt(role) !== 1) ? <div className="custom-label"><Form.Label data-bs-toggle="tooltip" data-bs-placement="top" title={email}>{email ? `${email.slice(0, 15)} ${email.length > 15 ? "..." : ""}` : ""}</Form.Label></div> : <Form.Control type="text" value={email} placeholder="" onChange={(e) => setEmail(e.target.value)} disabled={(parseInt(role) !== 1)} />
                            }</Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3" controlId="mobile">
                            <Form.Label>{messages.MobileNumber}*</Form.Label>
                            {(parseInt(role) !== 1) ? <div className="custom-label"><Form.Label>{mobile}</Form.Label></div> : <Form.Control type="text" placeholder="" value={mobile} onChange={(e) => setMobile(e.target.value)} disabled={(parseInt(role) !== 1)} />
                            }</Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3" controlId="office">
                            <Form.Label>{messages.OfficePhone}*</Form.Label>
                            {(parseInt(role) !== 1) ? <div className="custom-label"><Form.Label>{office}</Form.Label></div> : <Form.Control type="text" placeholder="" value={office} onChange={(e) => setOffice(e.target.value)} disabled={(parseInt(role) !== 1)} />
                            }</Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3" controlId="street">
                            <Form.Label>{messages.Street}*</Form.Label>
                            {(parseInt(role) !== 1) ? <div className="custom-label">
                                <Form.Label data-bs-toggle="tooltip" data-bs-placement="top" title={street}>{street ? `${street.slice(0, 10)} ${street.length > 10 ? "..." : ""}` : ""}</Form.Label></div>
                                : <Form.Control type="text" placeholder="" value={street} onChange={(e) => setStreet(e.target.value)} maxLength={250} disabled={(parseInt(role) !== 1)} />
                            }</Form.Group>


                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3" controlId="country">
                            <Form.Label>{messages.Country}*</Form.Label>
                            {(parseInt(role) !== 1) ? <div className="custom-label"><Form.Label>{country ? country.name : ""}</Form.Label></div>
                                :
                                <Form.Select aria-label="Default select example" value={country ? country.value : ""} onChange={handleSelectCountry} disabled={(parseInt(role) !== 1)}>
                                    {countryList.map((e, key) => {
                                        return <option key={"Country-" + key} value={e.value}>{e.label}</option>;
                                    })}
                                </Form.Select>
                            }
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3" controlId="State">
                            <Form.Label>{messages.State}*</Form.Label>

                            {(parseInt(role) !== 1) ? <div className="custom-label"><Form.Label>{state ? state.name : ""}</Form.Label></div>
                                :
                                <Form.Select aria-label="Default select example" value={state ? state.value : ""} onChange={handleSelectState} disabled={(parseInt(role) !== 1)} >
                                    {stateList.length ? stateList.map((e, key) => {
                                        return <option key={"State-" + key} value={e.value}>{e.label}</option>;
                                    }) : <option value="" disabled>State are not availble</option>}
                                </Form.Select>
                            }
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3" controlId="City">
                            <Form.Label>{messages.City}*</Form.Label>

                            {(parseInt(role) !== 1) ? <div className="custom-label"><Form.Label>{city ? city.name : ""}</Form.Label></div>
                                :
                                <Form.Select aria-label="Default select example" value={city ? city.value : ""} onChange={handleSelectCity} disabled={(parseInt(role) !== 1)} >
                                    {cityList.length ? cityList.map((e, key) => {
                                        return <option key={"City-" + key} value={e.value}>{e.label}</option>;
                                    }) : <option value="" disabled>City are not availble</option>}
                                </Form.Select>
                            }
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3" controlId="zipCode">
                            <Form.Label>{messages.ZipCode}*</Form.Label>
                            {(parseInt(role) !== 1) ? <div className="custom-label"><Form.Label data-bs-toggle="tooltip" data-bs-placement="top" title={zipCode}>{zipCode ? `${zipCode.slice(0, 10)} ${zipCode.length > 10 ? "..." : ""}` : ""}</Form.Label></div> : <Form.Control type="text" placeholder="" value={zipCode} onChange={(e) => setZipCode(e.target.value)} maxLength={250} disabled={(parseInt(role) !== 1)} />
                            }</Form.Group>
                    </Col>
                </Row>
            </Container>
            {(parseInt(role) === 1) ? <Row className="px-4">
                <Col lg={12} className="save-cancel-exit-btn">
                    <button
                        type="button"
                        className="button-custom saveBtn"
                        variant="primary"
                        onClick={() => history.goBack()}
                    >
                        {messages.cancel}
                    </button>
                    <button
                        className="button-custom saveBtn submitSaveBtn"
                        variant="primary"
                        type="submit"
                        disabled={(parseInt(role) === 1) ? disableBtn : "disable"}
                        onClick={submitHandler}
                    >
                        {messages.Save}
                    </button>
                </Col>
            </Row> : ""}

        </>
    )
}

export default SupportContactInfo
