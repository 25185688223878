import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { ReactTitle } from "react-meta-tags";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import "../../style/style.css";
import ActionConfirmationPopup from "../shared/action-confirmation-popup";
import { messages } from "../utils/constants";
import { focusById, isMatches, notify } from "../utils/utility";

const QuestionSetForm = () => {
  const history = useHistory();
  const invalidData = {
    name: false,
  };
  const [invalid, setInvalid] = useState(invalidData);
  const [questionSetAddDisabled, setQuestionSetAddDisabled] = useState(false);
  const [name, setName] = useState('');
  const [inputName, setInputName] = useState("");
  const [questions, setQuestions] = useState([
    { question: messages.StaticQuestion, rightAnswer: "", answers: [""] },
  ]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [show, setShow] = useState(false);
  const [editedForm, setEditedForm] = useState(false);
  const [popupValue, setPopupValue] = useState();
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [action, setAction] = useState("add");
  const [questionSetNameDisable, setQuestionSetNameDisable] = useState(true);
  const [editQuestionSetId, setEditQuestionSetId] = useState(localStorage.getItem("editQuestionSetId") || "");
  const copyQuestionSetId = localStorage.getItem("copyQuestionSetId") || "";
  const [questionsList, setQuestionsList] = useState([]);
  const [questionHasvalue, setQuestionHasvalue] = useState(false)

  useEffect(() => {
    getQuestionSetList();
  }, [])
  useEffect(()=>{
    if(inputName){
      setEditedForm(true)
    }
  },[inputName])
  useEffect(() => {
    if (inputName) {
      let questionHasvalue = true
      questions?.map((item) => {
        if (item?.question?.trim?.() === "") {
          questionHasvalue = false
        } else {
          if (!item?.rightAnswer) {
            questionHasvalue = false
          }
          if (!item?.answers || item?.answers.length == 0) {
            questionHasvalue = false
          }
        }
      })
      if (questionHasvalue) {
        setBtnDisabled(false)
        setQuestionSetAddDisabled(false)
      }
      setQuestionSetNameDisable(false)
    }
    else {
      setQuestionSetNameDisable(true)
    }
  }, [inputName])
  useEffect(() => {
    let questionHasvalue = true
    questions?.map((item) => {
      if (item?.question?.trim?.() === "") {
        questionHasvalue = false
      } else {
        if (!item?.rightAnswer) {
          questionHasvalue = false
        }
        if (!item?.answers || item?.answers.length == 0) {
          questionHasvalue = false
        }
      }
    })
    setQuestionHasvalue(questionHasvalue)
  }, [questions])

  useEffect(() => {
    if (editQuestionSetId) {
      getQuestionSetDetails();
      setAction("edit");
      setQuestionSetAddDisabled(true)
    }
    if (copyQuestionSetId) {
      setAction("copy");
    }
  }, [editQuestionSetId, copyQuestionSetId]);

  useEffect(() => {
    if (deleteIndex !== -1) {
      let errorMsg = messages.deleteQuestionMessage.replace(
        "<question>",
        deleteIndex + 1
      );
      setShow(true);
      setPopupValue({
        callBackFunction: handleRemoveClick,
        message: errorMsg,
        action: "Delete",
      });
    }
  }, [deleteIndex]);
  const validation = () => {
    let invalid = {
      name: false,
    };
    var flag = false;
    /*Validations*/
    if (!isMatches(name, messages.RegexAlphaNumericSpecialChar)) {
      flag = invalid.name = true;
      focusById("name");
      notify(messages.ToastError, messages.InvalidQuestionName);
    }
    setInvalid(invalid);
    return flag ? false : true;
  };

  const getQuestionSetDetails = () => {
    axios
      .post("/QuestionSets/getQuestionSetDetail", {
        id: editQuestionSetId || copyQuestionSetId,
      })
      .then(function (response) {
        // handle success
        let data = response.data ? response.data : {};
        if (!data.errorCode) {
          setName(
            data.name
              ? action === "copy"
                ? data.name + " copy"
                : data.name
              : ""
          );
          setInputName(
            data.name
              ? action === "copy"
                ? data.name + " copy"
                : data.name
              : ""
          )
          setQuestions(data.questions || []);
        } else {
          notify(messages.ToastError, messages.ServerNotResponding);
        }
      })
      .catch(function (error) { });
  };
  const getQuestionSetList = () => {
    const url = "/QuestionSets/getQuestionSetList";
    axios.post(url, {
      skip: 0,
      limit: 0,
      searchContent: "",
      fields: ["id", "name"],
      showTotalCount: false
    }).then((res) => {
      if (res.data) {
        let data = res.data;
        setQuestionsList(data?.map?.((quesSet, i) => ({
          id: i,
          value: quesSet.id,
          type: 'edit',
          label: quesSet.name
        })) || []);
      }
    }).catch((error) => {
      console.error(error);
    })
  }
  const submitHandler = (redirect) => {
    setEditedForm(false)
    localStorage.removeItem("cancelFormChanges")
    let isValid = validation();
    if (isValid) {
      if (action === "add" || action === "copy") {
        axios
          .post("/QuestionSets/addQuestionSet", { name, questions })
          .then(function (response) {
            if (response?.data?.id) {
              localStorage.setItem("editQuestionSetId", response.data.id)
              setAction("edit")
              setQuestionSetAddDisabled(true)
            }
            let data = response.data ? response.data : {};
            if (data.errorCode) {
              if (data.errorCode === 406) {
                notify(messages.ToastError, messages.QuestionSetAlreadyExist);
              } else {
                notify(messages.ToastError, messages.ServerNotResponding);
              }
            } else {
              notify(messages.ToastSuccess, messages.QuestionSetAdded);
              if (redirect) history.push("/Home/Questionnaire");
            }
          })
          .catch(function (error) {
            console.error("error");
            // history.push("/Home/Questionnaire");
          });
      }
      if (action === "edit") {
        axios
          .post("/QuestionSets/updateQuestionSet", { name, questions, id: editQuestionSetId })
          .then(function (response) {
            // handle success
            if (response?.data) {
              setQuestionSetAddDisabled(true)
            }
            let data = response.data ? response.data : {};
            if (data.errorCode) {
              if (data.errorCode === 406) {
                notify(messages.ToastError, messages.QuestionSetAlreadyExist);
              } else {
                notify(messages.ToastError, messages.ServerNotResponding);
              }
            } else if (data) {
              notify(messages.ToastSuccess, messages.QuestionSetUpdated);
              if (redirect) history.push("/Home/Questionnaire");
            }
          })
          .catch(function (error) {
            console.error(error);
            // history.push("/Home/Sensor");
          });
      }
      //localStorage.removeItem("editQuestionSetId")
      //localStorage.removeItem("copyAndEditSensorId")
    }
  };

  useEffect(() => {
    checkFieldValues();
  }, [questions]);

  /* start Questions */
  const handleRemoveClick = () => {
    setEditedForm(true);
    let ques = questions || [];
    ques.splice(deleteIndex, 1);
    if (ques.length === 0) {
      ques.push({
        question: messages.StaticQuestion,
        rightAnswer: "",
        answers: [""],
      });
    }
    setSelectedIndex(deleteIndex === 0 ? 0 : deleteIndex - 1);
    setQuestions([...ques]);
    setShow(false);
  };
  // const handleWarning = (event, index) => {
  //     event.preventDefault();
  //     setDeleteIndex(index);
  // }
  const handleCancelClick = () => {
    history.push("/Home/Questionnaire");
    setShow(false);
  };
  const handleCancelWarning = () => {
    if (editedForm) {
      setShow(true);
      setPopupValue({
        callBackFunction: handleCancelClick,
        headerName: "Alert",
        message: messages.cancelFormChanges,
        action: "Yes",
        cancelBtn: "No",
      });
    } else {
      history.push("/Home/Questionnaire");
    }
  };
  useEffect(() => {
    localStorage.setItem("cancelFormChanges", editedForm);
  }, [editedForm]);
  const handleAddClick = (e) => {
    e.preventDefault();
    setQuestions([
      ...questions,
      { question: messages.StaticQuestion, rightAnswer: "", answers: [""] },
    ]);
    setSelectedIndex(questions.length);
  };
  const handleEditClick = (event, index) => {
    event.preventDefault();
    setSelectedIndex(index);
  };
  const QuestionsSet = questions.map((value, index) => {
    let Color = selectedIndex === index ? "#FF7538" : "rgb(139 136 136)";
    return (
      <>
        <Row>
          <Col lg={9} md={9} sm={9} xs={9}>
            <Form.Group className="mb-3" controlId="formGroupAns">
              <div onClick={() => setSelectedIndex(index)}>
                {value.question}
              </div>
            </Form.Group>
          </Col>
          <Col lg={3} md={3} sm={3} xs={3}>
            <div className="sensorBtn">
              <i
                onClick={(e) => handleEditClick(e, index)}
                style={{ color: Color }}
                className="fas fa-pencil-alt me-5 pointer"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Edit"
              ></i>
              <i
                onClick={(e) => setDeleteIndex(index)}
                style={{ color: Color }}
                className="fas fa-trash-alt me-3 pointer"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Delete"
              ></i>
            </div>
          </Col>
        </Row>
      </>
    );
  });
  /* end Questions*/

  /* wrong answer*/
  const handleWrongAnsInputChange = (e, index) => {
    setEditedForm(true);
    const { value } = e.target;
    let ques = questions || [];
    ques[selectedIndex].answers[index] = value;
    setQuestions([...ques]);
  };
  const handleAddWrongAnsClick = (e) => {
    e.preventDefault();
    let ques = questions || [];
    ques[selectedIndex].answers.push("");
    setQuestions([...ques]);
  };
  const handleRemoveWrongAnsClick = (event, index) => {
    setQuestionSetAddDisabled(false)
    setEditedForm(true);
    event.preventDefault();
    let ques = questions || [];
    ques[selectedIndex].answers.splice(index, 1);
    setQuestions([...ques]);
  };
  const wrong = questions[selectedIndex].answers.map((value, index) => {
    return (
      <>
        <Row>
          <Col lg={6} md={6} sm={6} xs={6}>
            <Form.Group className="mb-2" controlId="formGroupAns">
              <Form.Control
                type="text"
                name="answer"
                value={value}
                onChange={(e) => handleWrongAnsInputChange(e, index)}
                placeholder=""
                maxLength={50}
              />
            </Form.Group>
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            <div className="mt-2">
              {index != 0 ? (
                <i
                  onClick={(e) => handleRemoveWrongAnsClick(e, index)}
                  style={{ color: "#FF7538" }}
                  className="fas fa-minus pointer"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Remove wrong answer"
                ></i>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </>
    );
  });
  /* end wrong Answer*/

  const handleChange = (e) => {
    setEditedForm(true);
    setQuestionSetAddDisabled(false)
    const { name, value } = e.target;
    let ques = questions || [];
    ques[selectedIndex][name] = value;
    setQuestions([...ques]);
  };
  const checkFieldValues = () => {
    let check = true;
    questions.map((value, index) => {
      if (
        value.question === "" ||
        value.rightAnswer === "" ||
        value.answers.filter((inst) => inst.trim() === "").length
      ) {
        check = false;
      }
    });
    if (name && check) {
      setBtnDisabled(false); //enable the button
    } else {
      setBtnDisabled(true); // Disable te button
    }
  };
  const onQuesSetSelect = (select, event) => {
    if (select) {
      setEditQuestionSetId(select.value);
      setInputName(select.label);
      setAction(select.type || 'edit');
      setName(select.label);
    } else {
      setAction('add');
    }
  }

  return (
    <Container>
      <ReactTitle
        title={
          action === "add"
            ? "Home-Add-Questionnsire"
            : "Home-Edit-Questionnsire"
        }
      />
      <ActionConfirmationPopup
        handleClose={() => setShow(false)}
        handleShow={() => setShow(true)}
        setShow={setShow}
        show={show}
        message={show ? popupValue?.message : ""}
        action={show ? popupValue?.action : ""}
        deactivateFunction={() => popupValue?.callBackFunction()}
        headerName={show ? popupValue?.headerName : ""}
        cancelBtn={show ? popupValue?.cancelBtn : ""}
      />
      <Row className="border-bottom">
        <div className="add-update-heading">
          {action === "add"
            ? messages.AddQuestionSet
            : action === "edit"
              ? messages.EditQuestionSet
              : messages.CopyQuestionSet}
        </div>
      </Row>
      <Row className="add-update-right-wrapper">
        <div className="row add-user-form-root">
          <div className="mt-3">
            <Row>
              <Col lg={6}>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label>{messages.QuestionSetName}*</Form.Label>
                      <Select
                        options={questionsList}
                        inputValue={inputName}
                        onInputChange={(val, actionMeta) => {
                          if (["input-blur", "menu-close"].includes(actionMeta?.action)) {
                            return;
                          }
                          setInputName(val)
                          setName(val)
                        }}
                        // value={questionsList.filter(function(option) {
                        //   return option.value === name;
                        // })}
                        value={{ label: name, value: name }}
                        onChange={onQuesSetSelect}
                      />
                      {/* <Form.Control
                        type="text"
                        placeholder=""
                        className={invalid.name ? " is-invalid" : ""}
                        value={name}
                        onChange={(e) => {
                          onChangeHandler(setName, e.target.value);
                        }}
                        maxLength={500}
                      /> */}
                    </Form.Group>
                  </Col>
                  <Col lg={12} className="mt-5">
                    <div className="sensor-ui">
                      <h5>{messages.Questions}</h5>
                      <i
                        style={{ color: "#FF7538" }}
                        className="fa fa-plus pointer"
                        onClick={handleAddClick}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Add question"
                      ></i>
                    </div>
                    <div className="sensorAddDiv">{QuestionsSet}</div>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} className="ps-5">
                <Row>
                  <Col lg={12} >
                    <Form.Group className="mb-3" controlId="question">
                      <Form.Label>{messages.Question}*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={
                          questions[selectedIndex].question ===
                            messages.StaticQuestion
                            ? ""
                            : questions[selectedIndex].question
                        }
                        onChange={(e) => handleChange(e)}
                        name="question"
                        maxLength={250}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className="mb-3 green-border"
                      controlId="rightAnswer"
                    >
                      <Form.Label>{messages.RightAnswer}*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={questions[selectedIndex].rightAnswer}
                        onChange={(e) => handleChange(e)}
                        name="rightAnswer"
                        maxLength={50}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group
                      className="mb-3 red-border"
                      controlId="formGroupRightAnswer"
                    >
                      <div className="question-set-ui">
                        <Form.Label>{messages.WrongAnswer}*</Form.Label>
                        <i
                          style={{ color: "#FF7538" }}
                          className="fa fa-plus"
                          onClick={handleAddWrongAnsClick}
                        ></i>
                      </div>
                      {wrong}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Row>
      <Row className="px-4">
        <Col lg={12} className="save-cancel-exit-btn">
          <button className="saveBtn m-1" onClick={handleCancelWarning}>
            {messages.cancel}
          </button>
          <button
            className="saveBtn m-1 submitSaveBtn"
            disabled={btnDisabled || questionSetAddDisabled || questionSetNameDisable || !questionHasvalue}
            onClick={() => submitHandler(false)}
          >
            {messages.Save}
          </button>
          <button
            className="saveBtn m-1 submitSaveBtn"
            disabled={btnDisabled || questionSetAddDisabled || questionSetNameDisable || !questionHasvalue}
            onClick={() => submitHandler(true)}
          >
            {messages.SaveAndExit}
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default QuestionSetForm;
