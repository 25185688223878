import React, { useCallback, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import Time from '../../../../../images/1200.png';
import DragerSkin from '../../../../../images/2800-battery-100.png';
import PreviewNotAvailable from "./PreviewNotAvailable";


const DragerXAM2800Skin = ({ data, alarm2, alarm1 }) => {
    const { height, width, triangleSize } = useMemo(() => ({ height: 1145, width: 864, triangleSize: 250 }), []);
    const createEquilateralTriangle = useCallback((radius, x, y) => {
        const height2 = radius * Math.sqrt(3);
        const halfHeight = height2 / 2;
        const halfSide = radius / 2;
        const points = [
            { x: x, y: y - radius },
            { x: x - halfHeight, y: y + halfSide },
            { x: x + halfHeight, y: y + halfSide },
        ];
        const pointsString = points.map(point => `${point.x},${point.y}`).join(' ');
        return <polygon points={pointsString} />;
    }, []);
    if (!Array.isArray(data) || !data.length) return <PreviewNotAvailable />;
    return (
        <Row>
            <Col lg={12}>
                <div className="drager-2800">
                    <div className="aspect-ratio-container">
                        <div className="content-box">
                            <div className="content">
                                <div className="header-bar">
                                    <img src={Time} alt="time" className="time" />
                                    <img src={DragerSkin} alt="battery" className="battery" />
                                </div>
                                <svg viewBox="0 0 864 1145" className="sensors-area" fill="#D8E8F8">
                                    {data.map((sensor, index) => {
                                        let labelPositionY = index * 0.25 * height + 40,
                                            labelPositionX = 864 - 60,
                                            unitPosition = labelPositionY + 113,
                                            alarmText1Position = {x:  width * 4 / 6, y:labelPositionY};
                                        let readings;
                                        switch (sensor.alarm) {
                                            case 'a1': readings = <>
                                                <image href="/icons/2800-a1.svg" x={alarmText1Position.x} y={alarmText1Position.y} width={triangleSize} />
                                                <text
                                                    x="100%"
                                                    y={labelPositionY}
                                                    textAnchor="end"
                                                    dominantBaseline="middle"
                                                    className="sensor-alarm-level"
                                                >
                                                    A
                                                </text>
                                            </>;
                                                break;
                                            case 'a2': readings = <>
                                                <image href="/icons/2800-a2.svg" x={alarmText1Position.x} y={alarmText1Position.y} width={triangleSize} />
                                                <text
                                                    x="100%"
                                                    y={labelPositionY}
                                                    textAnchor="end"
                                                    dominantBaseline="middle"
                                                    className="sensor-alarm-level"
                                                >
                                                    A
                                                </text>
                                            </>;
                                                break;
                                            default: readings = <text
                                                x={labelPositionX}
                                                y={labelPositionY}
                                                textAnchor="end"
                                                dominantBaseline="hanging"
                                                className="sensor-value"
                                            >
                                                {sensor.reading || 0}
                                            </text>
                                        }
                                        return <React.Fragment key={index}>
                                            <text
                                                x={0}
                                                y={labelPositionY}
                                                textAnchor="start"
                                                dominantBaseline="hanging"
                                                className="sensor-name"
                                            >
                                                {sensor?.sensorLabel || ""}
                                            </text>
                                            <text
                                                x={0}
                                                y={unitPosition}
                                                textAnchor="start"
                                                dominantBaseline="hanging"
                                                className="sensor-unit"
                                            >
                                                {sensor?.units?.[0]?.name || sensor?.units?.[0]?.label || ""}
                                            </text>
                                            {readings}
                                        </React.Fragment>
                                    })}
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
}
export default DragerXAM2800Skin;