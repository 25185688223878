import { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { chunk } from "../../../../utils/utility";
import PreviewNotAvailable from "./PreviewNotAvailable";

const MultiraeSkin = ({ data }) => {
    let sensorChunks = useMemo(() => chunk(data, 2), [data]);
    if (!Array.isArray(data) || !data.length) return <PreviewNotAvailable />;
    return (
        <Row >
            <Col xs={12}>
                <div className="multirae">
                    {
                        sensorChunks.map((sensorChunk, cIndex) => {
                            return (
                                <div key={`sensor-${cIndex}`} className="sensor-row">
                                    {
                                        sensorChunk.map((sensor, sensorIndex) => {
                                            return (
                                                <div key={`sensor-${cIndex}-${sensorIndex}`} className={`sensor-card ${sensor.alarm}`}>
                                                    <div className={`bg-black ${sensor.alarm ? "" : "text-white"} text-right p-1`}>
                                                        {sensor.sensorLabel}
                                                    </div>
                                                    <div className="">{sensor.reading}</div>
                                                    <div className="">{sensor?.units?.[0]?.name || sensor?.units?.[0]?.label || ""}</div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </Col>
        </Row>
    );
}
export default MultiraeSkin;