import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Spinner as BsSpinner, Container } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { useHistory } from "react-router-dom";
import GridFilter from "../grid/grid-filter";
import ReactGrid from "../grid/reactGrid";
import Spinner from "../spinner/spinner";
import { messages } from "../utils/constants";
import ShowEntries from "../utils/show-entries";
import {
  downloadJson,
  notify,
  openComponentPopupWindow,
} from "../utils/utility";

import { ReactTitle } from "react-meta-tags";
import ActionConfirmationPopup from "../shared/action-confirmation-popup";
import ImportFilePopup from "../shared/import-popup";
import QuestionSetImportLogPopup from "./popup/import-logs-popup/question-import-log";

const Questionnaire = () => {
  const userId = localStorage.getItem("userId");
  const organizationId = localStorage.getItem("organizationId");
  const history = useHistory();
  const role = localStorage.getItem("role");
  const [spinner, setSpinner] = useState(false);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(
    messages.GridRowCountOptions[0]
  );
  const [questionSetName, setQuestionSetName] = useState("");
  const [questionSetId, setQuestionSetId] = useState("");
  const [questionSetStatus, setQuestionSetStatus] = useState("");
  const [show, setShow] = useState(false);

  const [totalCount, setTotalCount] = useState();
  const [activePage, setActivePage] = useState(1);
  const [showTotalCount, setShowTotalCount] = useState(true);

  const [searchContent, setSearchContent] = useState("");
  const [status, setStatus] = useState(1);
  const [skip, setSkip] = useState(0);
  const [gridApiData, setGridApiData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [showImport, setShowImport] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  // const staticCellStyle = { color: "#FF7538" };
  const [rowData, setRowData] = useState([]);

  const GridActionsElement = useCallback((props) => {
    return (
      <>
        {props.data.name}
        <span className="elementDefault">
          {(parseInt(role) === 1 && props.data.isDefault) ||
          (props.data.organizationId === organizationId &&
            parseInt(role) === 2) ||
          props.data.createdBy === userId
            ? ""
            : "Default"}
        </span>
      </>
    );
  }, []);
  const [columnDefs] = useState([
    {
      field: "_id",
      maxWidth: 40,
      headerName: "",
      headerClass: "ag-custom-header",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    {
      headerName: "Set Name",
      field: "name",
      headerClass: "ag-custom-header",
      cellRendererFramework: GridActionsElement,
      maxWidth: 800,
      minWidth: 120,
    },
    {
      headerName: "Number of Questions",
      field: "question",
      headerClass: "ag-custom-header",
      minWidth: 150,
    },

    {
      headerName: "Actions",
      field: "Actions",
      headerClass: "ag-custom-header",
      cellRendererFramework: GridActions,
      pinned: "right",
      maxWidth: 150,
      minWidth: 100,
    },
  ]);

  useEffect(() => {
    localStorage.removeItem("editQuestionSetId");
    localStorage.removeItem("copyQuestionSetId");
  }, []);

  useEffect(() => {
    getQuestionSetList();
  }, [skip, itemsCountPerPage]);

  useEffect(() => {
    setTimeout(
      () =>
        gridApiData?.forEachNode?.((node) => {
          node.setSelected(
            selectedRows.some((selectedRow) => selectedRow.id === node.data.id)
          );
        }),
      300
    );
  }, [rowData, gridApiData]);

  function GridActions(props) {
    const status = () => {
      setOpen(props.data);
    };
    const edit = (e) => {
      localStorage.setItem("editQuestionSetId", props.data.id);
      history.push("/Home/QuestionSet/add-question-set");
    };
    const copy = (e) => {
      localStorage.setItem("copyQuestionSetId", props.data.id);
      history.push("/Home/QuestionSet/add-question-set");
    };

    return (
      <ul className="trigger-div mb-0 px-0">
        {(parseInt(role) === 1 && props.data.isDefault) ||
        (props.data.organizationId === organizationId &&
          parseInt(role) === 2) ||
        props.data.createdBy === userId ? (
          <i
            className="mx-4 fas fa-pencil-alt color-orange pointer"
            onClick={edit}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Edit"
          ></i>
        ) : (
          <i
            className="mx-4 fas fa-pencil-alt color-orange pointer sensor-edit"
            disabled
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Edit"
          ></i>
        )}
        <i
          className="mx-4 fas fa-trash color-orange pointer"
          onClick={status}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Delete"
        ></i>
      </ul>
    );
  }
  const setOpen = (data) => {
    handleShow();
    setQuestionSetName(data.name);
    setQuestionSetId(data.id);
    setQuestionSetStatus(data.status);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const containerStyle = {
    boxSizing: "border-box",
    height: rowData.length * messages.GridRowheight + messages.GridHeaderheight,
    minHeight: 150,
    width: "calc(100%)",
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setSkip(pageNumber * itemsCountPerPage.value - itemsCountPerPage.value);
  };
  const deleteFunction = (id) => {
    axios
      .post("/QuestionSets/deleteQuestionSet", { editQuestionSetId: id })
      .then((res) => {
        if (!res.data.errorCode) {
          notify(messages.ToastSuccess, messages.deleteSuccess);
          getQuestionSetList();
          handleClose();
        } else {
          notify(messages.ToastError, messages.somethingWentWrong);
          handleClose();
        }
      });
  };
  const getQuestionSetList = () => {
    const url = "/QuestionSets/getQuestionSetList";
    setSpinner(true);
    axios
      .post(url, {
        skip: skip,
        limit: itemsCountPerPage.value,
        searchContent: searchContent,
        fields: [
          "id",
          "name",
          "questions",
          "status",
          "organizationId",
          "isDefault",
          "createdBy",
        ],
        showTotalCount: showTotalCount,
      })
      .then((res) => {
        if (res.data) {
          setSpinner(false);
          let rows = [];
          let data = res.data.items;
          for (let i in data) {
            let questions = data[i].questions;
            let questionLabels = [];
            for (let k = 0; k < questions.length; k++) {
              questionLabels.push(questions[k].id);
            }
            let item = {};
            item.id = data[i].id;
            item.name = data[i].name;
            item.question = questionLabels.length;
            item.status = data[i].status;
            item.organizationId = data[i].organizationId;
            item.isDefault = data[i].isDefault;
            item.createdBy = data[i].createdBy;
            rows.push(item);
          }
          setRowData(rows);
        }
        setTotalCount(res.data.totalCount);
      })
      .catch((error) => {
        console.log(error);
        setSpinner(false);
      });
  };

  const onGridReady = (params) => {
    setGridApiData(params.api);
  };
  const onRowSelect = (params) => {
    if (params?.type === "rowSelected") {
      if (params.node.selected) {
        params?.data?.id &&
          setSelectedRows((prev) => {
            if (!prev?.some?.((i) => i.id === params.data.id))
              return [
                ...prev,
                { id: params.data.id, meter: params.data.meter },
              ];
            return prev;
          });
      } else {
        setSelectedRows((prev) =>
          prev.filter((selectedRow) => selectedRow.id !== params.data.id)
        );
      }
    }
  };
  
  const restoreFunction = useCallback(() => {
    setSpinner(true);
    axios
      .post("/QuestionSets/restoreDefault")
      .then((res) => {
        if (res) {
          setSpinner(false);
          getQuestionSetList();
          setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setSpinner(false);
      });
  },[]);
  const importQuestions = useCallback((file) => {
    setImportLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    axios
      .post("/QuestionSets/import-questions", formData)
      .then((res) => {
        if (res.status === 200) {
          if (!res?.data?.errorCode) {
            try {
              const log = res.data.reduce(
                (p, ques) => {
                  if (ques.error) p.error++;
                  else if (ques.id) p.created++;
                  p.totalCount++;
                  return p;
                },
                { created: 0, error: 0, totalCount: 0 }
              );
              if (log.error === log.totalCount)
                notify(
                  messages.ToastError,
                  `No question sets imported successfully out of ${log.totalCount}.`
                );
              else if (log.created <= log.totalCount) {
                notify(
                  log.created === log.totalCount
                    ? messages.ToastSuccess
                    : "warning",
                  `${log.created} of ${log.totalCount} question sets successfully imported.`
                );
                getQuestionSetList();
              }
              openComponentPopupWindow(res.data, {
                title: "Question import log",
                Component: QuestionSetImportLogPopup,
              });
              setShowImport(false);
            } catch (e) {
              console.log(e);
            }
          } else if (res?.data?.errorCode === 403)
            notify(messages.ToastError, `Incorrect JSON format.`);
          else notify(messages.ToastError, `Something went wrong.`);
        }
      })
      .catch((e) => {
        if (
          e?.response?.status === 403 &&
          e?.response?.data?.uploadSuccess === false
        )
          notify(messages.ToastError, `Incorrect JSON format.`);
        else notify(messages.ToastError, `Something went wrong.`);
      })
      .finally(() => setImportLoading(false));
  }, []);
  const exportQuestions = useCallback((e, selectedQuestions) => {
    e.target.disabled = true;
    setExportLoading(true);
    axios
      .post("/QuestionSets/export-questions", {
        selected: selectedQuestions?.map?.((i) => i.id) || [],
      })
      .then((res) => {
        if (res.status === 200) {
          if (!res?.data?.errorCode) {
            let d = new Date();
            downloadJson(
              res.data,
              `Questions ${d.toLocaleDateString().replace(/[/\s]/g, "-")}`
            )
              .then(() =>
                notify(
                  messages.ToastSuccess,
                  "Questions exported successfully."
                )
              )
              .catch(() =>
                notify(messages.ToastError, "Error exporting questions.")
              );
          } else
            notify(
              messages.ToastError,
              res?.data?.errorCode === 405
                ? "No question set to export."
                : "Error exporting questions."
            );
        }
      })
      .catch((e) => {
        notify(messages.ToastError, e?.message);
      })
      .finally(() => setExportLoading(false));
  }, []);
  return (
    <Container fluid className="px-0 body-component">
      <ReactTitle title="Home-Questionnaires" />
      <ActionConfirmationPopup
        handleClose={handleClose}
        handleShow={handleShow}
        setShow={setShow}
        show={show}
        message={
          show &&
          questionSetStatus === 1 &&
          messages.deleteQuestionSetMessage +
            ` "${questionSetName}" question set?`
        }
        action={show && questionSetStatus === 1 && messages.delete}
        deactivateFunction={() => deleteFunction(questionSetId)}
      />
      <ImportFilePopup
        accepts="application/json"
        actionLabel="Upload"
        show={showImport}
        isLoading={importLoading}
        handleClose={() => setShowImport(false)}
        headerName={"Import Question Set"}
        onConfirm={importQuestions}
      />
      <div className="container-fluid mt-3">
        <div className="row">
          <p className="font-bold mb-1"> Manage Questionnaire</p>
        </div>
        <hr />
        <div className="row mt-5">
          <GridFilter
            role={role}
            entries
            search
            restore
            restoreFunction={restoreFunction}
            itemsCountPerPage={itemsCountPerPage}
            setItemsCountPerPage={(e) => {
              setSpinner(true);
              setSkip(0);
              setItemsCountPerPage(e);
              setSpinner(false);
            }}
            setStatus={setStatus}
            status={status}
            addBtn={true}
            addBtnPath={"/Home/QuestionSet/add-question-set"}
            ajaxCall={getQuestionSetList}
            setSearchContent={(e) => {
              setSpinner(true);
              setSkip(0);
              setSearchContent(e);
              setSpinner(false);
            }}
            tooltipTitle={"Questionnaire"}
            setSkip={setSkip}
            setActivePage={setActivePage}
          />
        </div>
        <div className="row mb-5">
          <div className="import-export w-auto flex gap-2">
            <button
              disabled={importLoading}
              className="btn btn-secondary mr-sm-3 organization-plus-select pointer w-auto"
              onClick={() => setShowImport(true)}
              type="button"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Import JSON"
            >
              {importLoading ? (
                <BsSpinner className="spinner-border spinner-border-sm" />
              ) : (
                <i className="fas fa-upload"></i>
              )}
            </button>
            <button
              disabled={exportLoading}
              className="btn btn-secondary mr-sm-3 organization-plus-select pointer w-auto"
              onClick={(e) => exportQuestions(e, selectedRows)}
              type="button"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={
                !selectedRows?.length
                  ? "Export all as JSON"
                  : "Export selected as JSON"
              }
            >
              {exportLoading ? (
                <BsSpinner className="spinner-border spinner-border-sm" />
              ) : (
                <i className="fas fa-download"></i>
              )}
            </button>
          </div>
        </div>
        <div className="container-fluid grid-list-div mb-3 ">
          <div className=" organization-table">
            <div className="ag-theme-alpine application-grid">
              {spinner ? (
                <Spinner />
              ) : (
                <ReactGrid
                  id="requestConsultGrid"
                  gridId="Request-consult-List"
                  containerStyle={containerStyle}
                  columnDefs={columnDefs}
                  rowData={rowData}
                  checkboxSelection
                  rowSelection="multiple"
                  suppressRowClickSelection
                  onGridReady={onGridReady}
                  onRowSelect={onRowSelect}
                />
              )}
            </div>
            {totalCount > 10 ? (
              <div className="row mb-5">
                <div className="col-sm-6 font-bold mt-2 d-flex gx-1">
                  <ShowEntries
                    totalCount={totalCount}
                    itemsCountPerPage={itemsCountPerPage.value}
                    activePage={activePage}
                  />
                  <div className="mx-1">
                    {" "}
                    | Selected {selectedRows.length} of {totalCount}
                  </div>
                </div>
                <div className="col-md-6 pagination-component">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={itemsCountPerPage.value}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={2}
                    onChange={handlePageChange}
                    itemClass="pagination-item"
                    linkClass="pagination-link"
                    prevPageText="Previous"
                    nextPageText="Next"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Questionnaire;
