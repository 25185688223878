import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { messages } from "../utils/constants";
import { Container } from "react-bootstrap";
import Spinner from "../spinner/spinner";
import ReactGrid from "../grid/reactGrid";
import ShowEntries from "../utils/show-entries";
import Pagination from "react-js-pagination";
import GridFilter from "../grid/grid-filter";
import { toDefaultDateFormat, durationCalculate } from "../utils/utility";
import axios from "axios";
import { ReactTitle } from "react-meta-tags";
const AcceptedAgreements = () => {
  const [spinner, setSpinner] = useState(false);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(
    messages.GridRowCountOptions[0]
  );
  const userRole = localStorage.getItem("role");
  const [showTotalCount /* , setShowTotalCount */] = useState(true);
  const [searchContent, setSearchContent] = useState("");
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [totalCount, setTotalCount] = useState();
  const [activePage, setActivePage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [versions, setVersions] = useState([]);
  const [version, setVersion] = useState({});

  useEffect(() => {
    getVersions();
  }, []);

  const getVersions = () => {
    const url = new URL("/Agreements/getAllAgreement", window.location.href);
    setSpinner(true);
    axios
      .post(url)
      .then(function (response) {
        setSpinner(false);
        let _version = [];
        let data = response?.data.agreements;
        for (let i in data) {
          let item = {};
          item.label = data[i].version;
          item.value = data[i].version;
          _version.push(item);
        }
        setVersion({});
        setVersions(_version);
      })
      .catch((error) => {
        console.log(error);
        setSpinner(false);
      });
  };

  const getAllAcceptedAgreement = () => {
    const url = new URL(
      "/AcceptAgreements/getAllAcceptedAgreement",
      window.location.href
    );
    setSpinner(true);
    let endDate = to ? new Date(to) : "";
    endDate?.setHours?.(24, 0, 0, -1);
    endDate = endDate?.toISOString?.() || endDate;
    axios
      .post(url, {
        skip: skip,
        limit: itemsCountPerPage.value,
        search: searchContent,
        startDate: from ? new Date(from).toISOString() : "",
        endDate,
        version: version?.value || "",
      })
      .then(function (response) {
        setSpinner(false);
        let rows = [];
        let data = response?.data?.agreement;
        for (let i in data) {
          let item = {};
          item.userName = data[i]?.userAccount?.name;
          item.organisationName = data[i]?.organization?.name;
          item.agreementName = data[i]?.agreement?.agreementName;
          item.version = data[i]?.version;
          item.createdAt = data[i].createdAt
            ? toDefaultDateFormat(
                data[i].createdAt,
                messages.momentDateWithTime
              )
            : "";

          rows.push(item);
        }
        setRowData(rows);
        setTotalCount(response.data.totalCount);
      })
      .catch((error) => {
        console.log(error);
        setSpinner(false);
      });
  };
  useEffect(() => {
    getAllAcceptedAgreement();
  }, [skip, itemsCountPerPage, from, to, version]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setSkip(pageNumber * itemsCountPerPage.value - itemsCountPerPage.value);
  };
  const columnDefs = [
    {
      headerName: "User Name",
      field: "userName",
      headerClass: "ag-custom-header",
      maxWidth: 300,
      minWidth: 300,
    },
    {
      headerName: "Organization Name",
      field: "organisationName",
      headerClass: "ag-custom-header",
      maxWidth: 300,
      minWidth: 300,
    },
    {
      headerName: "Agreement Name",
      field: "agreementName",
      headerClass: "ag-custom-header",
      maxWidth: 300,
      minWidth: 300,
    },
    {
      headerName: "Version",
      field: "version",
      headerClass: "ag-custom-header",
      minWidth: 150,
    },
    {
      headerName: "Accepted Date/Time",
      field: "createdAt",
      headerClass: "ag-custom-header",
      minWidth: 200,
    },
  ];
  const containerStyle = {
    boxSizing: "border-box",
    height: rowData.length * messages.GridRowheight + messages.GridHeaderheight,
    minHeight: 150,
    width: "calc(100%)",
  };
  return (
    <Container fluid className="px-0 body-component">
      <ReactTitle title="Home-AcceptedAgreements" />
      <div className="container-fluid mt-3">
        <div className="row">
          <p className="font-bold mb-1">Accepted Agreements</p>
        </div>
        <hr />

        <div className="row my-5">
          <GridFilter
            entries={true}
            search={true}
            filter={true}
            role={userRole}
            ajaxCall={getAllAcceptedAgreement}
            version={true}
            itemsCountPerPage={itemsCountPerPage}
            versions={versions}
            setItemsCountPerPage={(e) => {
              setSpinner(true);
              setSkip(0);
              setItemsCountPerPage(e);
              setSpinner(false);
            }}
            restore={false}
            setSearchContent={(e) => {
              setSpinner(true);
              setSkip(0);
              setSearchContent(e);
              setSpinner(false);
            }}
            setVersion={(e) => {
              setSpinner(true);
              setSkip(0);
              setVersion(e);
              setSpinner(false);
            }}
            setTo={setTo}
            from={from}
            setFrom={setFrom}
            tooltipTitle={"Meter"}
            setSkip={setSkip}
            setActivePage={setActivePage}
          />
        </div>
        <div className="container-fluid grid-list-div mb-3 ">
          <div className=" organization-table">
            <div className="ag-theme-alpine application-grid">
              {spinner ? (
                <Spinner />
              ) : (
                <ReactGrid
                  id="requestConsultGrid"
                  gridId="Request-consult-List"
                  containerStyle={containerStyle}
                  columnDefs={columnDefs}
                  rowData={rowData}
                />
              )}
            </div>
            {totalCount > 10 ? (
              <div className="row mb-5">
                <div className="col-sm-6 font-bold mt-2">
                  <ShowEntries
                    totalCount={totalCount}
                    itemsCountPerPage={itemsCountPerPage.value}
                    activePage={activePage}
                  />
                </div>
                <div className="col-md-6 pagination-component">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={itemsCountPerPage.value}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={2}
                    onChange={handlePageChange}
                    itemClass="pagination-item"
                    linkClass="pagination-link"
                    prevPageText="Previous"
                    nextPageText="Next"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AcceptedAgreements;
