import axios from "axios";
import { React, useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { ReactTitle } from "react-meta-tags";
import { useHistory } from "react-router-dom";
import Spinner from "../spinner/spinner";
import "../../style/style.css";
import GridFilter from "../grid/grid-filter";
import ReactGrid from "../grid/reactGrid";
import { messages } from "../utils/constants";
import ShowEntries from "../utils/show-entries";
import { toDefaultDateFormat } from "../utils/utility";
function Agreements({ state, data }) {
  const [status, setStatus] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [searchContent, setSearchContent] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(
    messages.GridRowCountOptions[0]
  );
  const [totalCount, setTotalCount] = useState();
  const [showTotalCount, setShowTotalCount] = useState(true);
  const [skip, setSkip] = useState(0);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const history = useHistory();
  const statusOptions = ["Published", "Draft"];

  useEffect(() => {
    localStorage.removeItem("editAgreementId");
    localStorage.removeItem("viewAgreementId");
  }, []);

  function GridActions(props) {
    const editAndView = (e) => {
      if (+props.data.statusCode === 1) {
        localStorage.setItem("viewAgreementId", props.data.id);
        localStorage.setItem("editAgreementId", "");
      } else {
        localStorage.setItem("editAgreementId", props.data.id);
        localStorage.setItem("viewAgreementId", "");
      }
      history.push("/Home/Agreements/add-agreement");
    };
    return (
      <ul className="trigger-div mb-0 px-0">
        <i
          className={`mx-4 fas ${
            +props.data.statusCode === 1 ? "fa-eye" : "fa-pencil-alt"
          } color-orange pointer`}
          onClick={editAndView}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={+props.data.statusCode === 1 ? "View" : "Edit"}
        ></i>
      </ul>
    );
  }

  const [rowData, setRowData] = useState([]);
  const columnDefs = useMemo(
    () =>
      [].concat(
        {
          headerName: "Agreement Name",
          field: "agreementName",
          headerClass: "ag-custom-header",
          // maxWidth: 370,
          minWidth: 250,
        },
        {
          headerName: "Version",
          field: "version",
          headerClass: "ag-custom-header",
          minWidth: 180,
        },
        {
          headerName: "Upload Date/Time",
          field: "uploadDate",
          headerClass: "ag-custom-header",
          // maxWidth: 180,
          minWidth: 250,
        },
        {
          headerName: "Effective Date/Time",
          field: "effectiveDate",
          headerClass: "ag-custom-header",
          // maxWidth: 180,
          minWidth: 250,
        },
        {
          headerName: "Status",
          field: "status",
          headerClass: "ag-custom-header",
          // maxWidth: 120,
          minWidth: 120,
        },
        {
          headerName: "Actions",
          field: "Actions",
          headerClass: "ag-custom-header",
          cellRendererFramework: GridActions,
          pinned: "right",
          maxWidth: 100,
          // minWidth: 100,
        },
      ),
    [GridActions]
  );
  const getAgreementsList = () => {
    
    const url = new URL("/Agreements/getAllAgreement", window.location.href);
    setSpinner(true);
    axios
      .post(url, {
        skip: skip,
        limit: itemsCountPerPage.value,
        search: searchContent,
      })
      .then(function (response) {
        setSpinner(false);
        let rows = [];
        let data = response?.data.agreements;
        for (let i in data) {
          let data = response?.data.agreements;
          let item = {};
          item.agreementName = data[i]?.agreementName;
          item.version = data[i]?.version;

          item.uploadDate = data[i].createdAt
            ? toDefaultDateFormat(
                data[i].createdAt,
                messages.momentDateWithTime
              )
            : "";
          item.effectiveDate = data[i].effectiveDate
            ? toDefaultDateFormat(
                data[i].effectiveDate,
                messages.momentDateWithTime
              )
            : "";
          item.status = +data[i].status === 1 ? "Published" : "Draft";
          item.statusCode = data[i].status;
          item.id = data[i].id;
          rows.push(item);
        }
        setRowData(rows);
        setTotalCount(response.data?.count);
      })
      .catch((error) => {
        console.log(error);
        setSpinner(false);
      });
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setSkip(pageNumber * itemsCountPerPage.value - itemsCountPerPage.value);
  };
  useEffect(() => {
    getAgreementsList();
  }, [skip, itemsCountPerPage, status]);

  const containerStyle = {
    boxSizing: "border-box",
    height: rowData.length * messages.GridRowheight + messages.GridHeaderheight,
    minHeight: 150,
    width: "calc(100%)",
  };
  return (
    <Container fluid className="px-0 body-component">
      <ReactTitle title="Home-Agreements" />
      <div className="container-fluid mt-3">
        <div className="row">
          <p className="font-bold mb-1"> Agreements</p>
        </div>
        <hr />
        <div className="row my-5">
          <GridFilter
            entries={true}
            search={true}
            tooltipTitle="Agreement"
            ajaxCall={getAgreementsList}
            setSearchContent={(e) => {
              setSpinner(true);
              setSkip(0);
              setSearchContent(e);
              setSpinner(false);
            }}
            itemsCountPerPage={itemsCountPerPage}
            setItemsCountPerPage={(e) => {
              setSpinner(true);
              setSkip(0);
              setItemsCountPerPage(e);
              setSpinner(false);
            }}
            setStatus={(e) => {
              setStatus(e);
            }}
            addBtn={true}
            addBtnPath={"/Home/Agreements/add-agreement"}
            setSkip={setSkip}
            setActivePage={setActivePage}
          />
        </div>
        <div className="container-fluid organization-list-div mb-3 ">
          <div className=" organization-table">
            <div className="ag-theme-alpine application-grid">
              <ReactGrid
                id="requestConsultGrid"
                gridId="Request-consult-List"
                containerStyle={containerStyle}
                columnDefs={columnDefs}
                rowData={rowData}
              />
            </div>
            {totalCount > 10 ? (
              <div className="row mb-5 mt-2">
                <div className="col-sm-6 font-bold mt-2">
                  <ShowEntries
                    totalCount={totalCount}
                    itemsCountPerPage={itemsCountPerPage.value}
                    activePage={activePage}
                  />
                </div>
                <div className="col-md-6 pagination-component">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={itemsCountPerPage.value}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={2}
                    onChange={handlePageChange}
                    itemClass="pagination-item"
                    linkClass="pagination-link"
                    prevPageText="Previous"
                    nextPageText="Next"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}
export default Agreements;
