import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { messages } from "../utils/constants";

const ActionDeletePopup = ({
  handleClose,
  headerName,
  cancelBtn,
  viewImage,
  show,
  message,
  action,
  deactivateFunction,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <div
          style={{
            paddingLeft: "2rem",
            paddingRight: "2rem",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <Modal.Header closeButton className="py-0  px-0 Heading-for-modal">
            <p style={{ fontSize: "1.3rem" }}>
              {confirmDelete ? messages.areYouSure : messages.TagImage}
            </p>
          </Modal.Header>
          <Modal.Body className="px-0 pb-0">
            <div>
              <div className="justify-content-center d-flex">
                <p
                  className="text-center"
                  style={{ fontSize: "1.3rem", wordBreak: "break-word" }}
                >
                  {confirmDelete ? messages.ConfirmDeleteAction : message}
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="px-0">
            {confirmDelete ? (
              <div className="submitAndCloseBtn">
                <button
                  className={`button-custom submitBtn forgot-password-button `}
                  variant="primary"
                  onClick={deactivateFunction}
                >
                  {action}
                </button>
                <button
                  className="button-custom closeBtn"
                  variant="primary"
                  onClick={handleClose}
                >
                  {messages.cancel}
                </button>
              </div>
            ) : (
              <div className="submitAndCloseBtn">
                <button
                  className={`button-custom submitBtn forgot-password-button `}
                  variant="primary"
                  onClick={() => {
                    setConfirmDelete(true);
                  }}
                >
                  {action}
                </button>
                <button
                  className="button-custom submitBtn forgot-password-button"
                  variant="primary"
                  onClick={()=>{
                      window.open(messages.GraphImageUrl + viewImage, "_blank")
                      handleClose();
                  }}
                >
                  {messages.ViewImage}
                </button>
              </div>
            )}
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
export default ActionDeletePopup;
