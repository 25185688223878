import { useCallback, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import HazsimLogo from "../../../../../images/HazSim.svg";
import { chunk, getBarColor } from "../../../../utils/utility";
import PreviewNotAvailable from "./PreviewNotAvailable";

const HazsimDigitalSkin = ({ data }) => {
    let sensorChunks = useMemo(() => chunk(data, 2), [data]);
    let chunkLength = useMemo(() => sensorChunks.length, [sensorChunks]);
    const customHtml = useCallback((sensor, customClass, index) => {
        if (sensor) {
            switch (sensor.type) {
                case 2: return <td><div className={`sensor-card ${customClass}`}>
                    <div className={`bar-sensor-label`}>
                        <div className="value">{sensor.sensorLabel}</div>
                        <div className="bars">
                            {Array.from({ length: sensor.maxValue }).map((_, index) => (
                                <div key={`bar-${index}`} className={`bar-line ${getBarColor(index, +sensor.reading, sensor.range || {})}`}></div>
                            ))}
                        </div>
                    </div>
                </div></td>;
                default: return <td className={sensor?.alarm || ""}>
                    <div className={`sensor-card ${customClass}`}>
                        <div className="value">{sensor.reading}</div>
                        <div className="title">
                            {sensor.sensorLabel}
                            {" "}
                            {sensor?.units?.[0]?.name || sensor?.units?.[0]?.label || ""}
                        </div>
                    </div>
                </td>
            }
        }
    }, []);
    if (!Array.isArray(data) || !data.length) return <PreviewNotAvailable />;
    return (
        <Row>
            <Col xs={12}>
                <div className="hazsim-digital">
                    <table>
                        <tbody>
                            {
                                sensorChunks.map((sensorChunk, cIndex) => {
                                    return (
                                        <tr key={`sensor-${cIndex}`} className="F">
                                            {customHtml(sensorChunk?.[0], "text-left")}
                                            {
                                                cIndex === 0
                                                    ? <td rowSpan={chunkLength} className="hazsim-logo text-center">
                                                        <div className="">
                                                            <img src={HazsimLogo} alt="HazSim Logo" style={{ maxWidth: "35px", width: "35px !important", fill: "white" }} />
                                                        </div>
                                                    </td>
                                                    : <></>
                                            }
                                            {customHtml(sensorChunk?.[1], "text-right")}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Col>
        </Row>
    );
}
export default HazsimDigitalSkin;