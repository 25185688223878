import React, { useEffect, useMemo, useState } from "react";
import '../../../../style/global.css';
import '../../../../style/style.css';
import '../../../../index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const MeterImportLogPopup = ({ data }) => {
  const { error, success, total } = useMemo(() => {
    return (
      data?.reduce?.(
        (p, c) => {
          if (c.error) p.error++;
          else p.success++;
          return p;
        },
        { error: 0, total: data?.length || 0, success: 0 }
      ) || { error: 0, total: data?.length || 0, success: 0 }
    );
  }, [data]);
  return (
    <>
      <style dangerouslySetInnerHTML={{__html:`
    html,body {
        margin: 0;
        padding: 0;
    }
    
    .w-full{
        width: 100%;
    }
    table,tr,td,th {
        border: 1px solid black;
        border-collapse: collapse;
    }
    .container{
        width: 100%;
        padding: 15px;
        max-width: 800px;
        margin: auto;
    }
    .row{
        padding: 0;
        margin: 15px;
    }
    .row+.row{
        margin-top: 50px;
    }
    
    th, td {
        padding: 5px;
    }
    table{
        border-radius: 5px;
    }
    tr:first-child{
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    tr:first-child td:first-child, tr:first-child th:first-child{
        border-top-left-radius: 5px;
    }
      `}}></style>
      <div className="container">
        <div className="row mb-5 p-2">
          <h3 className="col-xs-12">
            {success || "None"} of {total} meters imported.
          </h3>
        </div>
        {data?.map?.((meterLog, index) => {
          return (
            <div className="row" key={index}>
              <div className="col-12">
                <table className="w-full">
                  <tbody>
                    <tr>
                      <th colSpan={2}>{index + 1}. {meterLog.name}</th>
                    </tr>
                    <tr>
                      <th>Imported</th>
                      <td>{meterLog.error ? "No" : "Yes"}</td>
                    </tr>
                    <tr>
                      <th>Message(if any)</th>
                      <td>{meterLog.errorMessage || ""}</td>
                    </tr>
                    {
                      meterLog?.sensorLogs?.length
                        ?
                        <tr>
                          <td colSpan={2}>
                            <bold>Sensors:</bold>
                            <table className="w-full">
                              <thead>
                                <th>Name</th>
                                <th>Imported</th>
                                <th>Message(if any)</th>
                              </thead>
                              <tbody>
                                {
                                  meterLog.sensorLogs.map((log, i2) => (<tr key={i2}>
                                    <td>{log.name}</td>
                                    <td>{log.error ? (log.attached ? "Yes" : "No") : "Yes"}</td>
                                    <td>{log.errorMessage}</td>
                                  </tr>))
                                }
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        : ""
                    }
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default MeterImportLogPopup;
