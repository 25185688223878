import { useCallback, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import PreviewNotAvailable from "./PreviewNotAvailable";

const AltairSkin = ({ data }) => {
    const { viewBoxHeight, viewBoxWidth, paddingV, paddingH } = useMemo(() => ({
        viewBoxHeight: 1700, viewBoxWidth: 1080, paddingV: 100,
        paddingH: 40,
    }), []);
    const getSensorValuePosition = useCallback(({ width, height, paddingV, paddingH, index, fontSize }) => {
        let fontHalfSize = fontSize / 2;
        switch (index) {
            case 0: return {
                valueX: paddingH,
                valueY: (height / 2) - fontHalfSize,
                textAnchor: "start",
                dominantBaseline: "text-top",
                unitX: paddingH,
                unitY: (height / 2) + fontHalfSize,
                unitTextAnchor: "start",
                unitDominantBaseline: "hanging"
            }
            case 1: return {
                textAnchor: "start",
                dominantBaseline: "text-top",
                valueX: paddingH,
                valueY: paddingV,
                unitX: paddingH,
                unitY: paddingV + fontSize,
                unitTextAnchor: "start",
                unitDominantBaseline: "hanging"
            }
            case 2: return {
                textAnchor: "end",
                dominantBaseline: "text-top",
                valueX: width - paddingH,
                valueY: paddingV,
                unitX: width - paddingH,
                unitY: paddingV + fontSize,
                unitTextAnchor: "end",
                unitDominantBaseline: "hanging"
            }
            case 3: return {
                textAnchor: "end",
                dominantBaseline: "text-top",
                valueX: width - paddingH,
                valueY: (height / 2) - fontHalfSize,
                unitX: width - paddingH,
                unitY: (height / 2) + fontHalfSize,
                unitTextAnchor: "end",
                unitDominantBaseline: "hanging"
            }
            case 4: return {
                x: width - paddingH,
                y: height - paddingV,
                textAnchor: "end",
                dominantBaseline: "text-top",
                valueX: width - paddingH,
                valueY: height - paddingV - fontSize * 2,
                unitX: width - paddingH,
                unitY: height - paddingV,
                unitTextAnchor: "end",
                unitDominantBaseline: "text-top"
            }
            case 5: return {
                x: paddingH,
                y: height - paddingV,
                textAnchor: "start",
                dominantBaseline: "text-top",
                valueX: paddingH,
                valueY: height - paddingV - fontSize * 2,
                unitX: paddingH,
                unitY: height - paddingV,
                unitTextAnchor: "start",
                unitDominantBaseline: "text-top"
            }
            default: return null;
        }
    }, []);
    if (!Array.isArray(data) || !data.length) return <PreviewNotAvailable />;
    const circleDiameter = viewBoxWidth / 2.25;

    const centerX = viewBoxWidth / 2;
    const centerY = viewBoxHeight / 2;
    const radius = circleDiameter / 2;

    const lineX = viewBoxWidth / 2; // Position the line at center of the viewBox

    const linePoints = [], // lines to slice the circle for template
        slicePaths = [], // Polyline paths for each pizza shaped slice for sensor
        sliceCenters = []; // exact center of the slice for sensor label
    const angleIncrement = (2 * Math.PI) / 6; // Divide circle into 6 equal parts

    for (let i = 0; i < 6; i++) { // Generating the endpoints of the lines to draw from center of the circle to slice the circle.
        const angle = i * angleIncrement;
        const x = centerX + (circleDiameter / 2) * Math.sin(angle);
        const y = centerY - (circleDiameter / 2) * Math.cos(angle);
        linePoints.push({ x, y });
    }

    const intersectionPoints = linePoints.map((point, i) => {
        if ([0, 3].includes(i)) return point;
        const slope = (point.y - centerY) / (point.x - centerX);
        let x, y;
        if (Math.abs(slope) > viewBoxHeight / viewBoxWidth) {
            // Intersection with top or bottom edge
            // y = slope > 0 ? viewBoxHeight : 0;
            x = centerX + ((y - centerY) / slope);
        } else {
            // Intersection with left or right edge
            x = point.x > centerX ? viewBoxWidth : 0;
            // y = centerY + ((x - centerX) * slope);
        }
        return { x, y: point.y };
    });

    for (let i = 0; i < 6; i++) {
        let sensor = data?.[i] || {};
        const startAngle = (i - 2) * angleIncrement;
        const endAngle = (i - 1) * angleIncrement;

        const startPointX = centerX + (radius * Math.sin(startAngle));
        const startPointY = centerY - (radius * Math.cos(startAngle));
        const endPointX = centerX + (radius * Math.sin(endAngle));
        const endPointY = centerY - (radius * Math.cos(endAngle));

        const pathData = `M ${centerX},${centerY} L ${startPointX},${startPointY} A ${radius},${radius} 0 0,1 ${endPointX},${endPointY} Z`;

        const radiusMidPointX = centerX + (radius / 1.5) * Math.sin((startAngle + endAngle) / 2);
        const radiusMidPointY = centerY - (radius / 1.5) * Math.cos((startAngle + endAngle) / 2);

        sliceCenters.push({ x: radiusMidPointX, y: radiusMidPointY });

        let valuePositions = getSensorValuePosition({
            width: viewBoxWidth,
            height: viewBoxHeight,
            paddingV,
            paddingH,
            index: i,
            fontSize: 20
        })
        slicePaths.push(
            <g className={`sensor-data ${sensor?.alarm || ""}`} key={i} >
                {sensor?.sensorLabel && <>
                    <path
                        key={i}
                        className="sensor-slice"
                        fill="#00ff00"
                        d={pathData}
                        stroke="black"
                    />
                    <text x={radiusMidPointX} y={radiusMidPointY} dominantBaseline="middle" textAnchor="middle" className="sensor-name">
                        {sensor?.sensorLabel || ""}
                    </text>
                </>}
                <text
                    x={valuePositions.valueX}
                    y={valuePositions.valueY}
                    textAnchor={valuePositions.textAnchor}
                    dominantBaseline={valuePositions.dominantBaseline}
                    className="sensor-value"
                >
                    {sensor?.reading || "--"}
                </text>
                <text
                    x={valuePositions.unitX}
                    y={valuePositions.unitY}
                    textAnchor={valuePositions.unitTextAnchor}
                    dominantBaseline={valuePositions.unitDominantBaseline}
                    className="sensor-unit"
                >
                    {sensor?.units?.[0]?.name || sensor?.units?.[0]?.label || "--"}
                </text>
            </g>
        );

    }
    data.forEach((sensor, i) => {
        if (i > 5) return;

    })

    return (
        <Row>
            <Col lg={12}>
                <div className="altair">
                    <div className="device">
                        <svg
                            viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            height="100%"
                        >
                            <circle cx={centerX} cy={centerY} r={circleDiameter / 2} fill="none" stroke="black" />

                            {linePoints.map((point, index) => ( // lines to slice the circle
                                <line
                                    key={index}
                                    x1={centerX}
                                    y1={centerY}
                                    x2={point.x}
                                    y2={point.y}
                                    stroke="black"
                                />
                            ))}

                            {linePoints.map((point, index) => ( // lines extended from intersection of circle
                                <line
                                    key={index}
                                    x1={intersectionPoints[index].x}
                                    y1={intersectionPoints[index].y}
                                    x2={point.x}
                                    y2={point.y}
                                    stroke="black"
                                />
                            ))}

                            <line x1={lineX} y1={0} x2={lineX} y2={viewBoxHeight} stroke="black" />
                            {slicePaths}
                        </svg>
                    </div>
                </div>
            </Col>
        </Row>
    );
}
export default AltairSkin;