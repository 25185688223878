import { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import PreviewNotAvailable from "./PreviewNotAvailable";

const DragerXAM3500Skin = ({ data }) => {
    const { height, width, containerHeight } = useMemo(() => ({ height: 1219, width: 1080, containerHeight: 244 }), []);
    if (!Array.isArray(data) || !data.length) return <PreviewNotAvailable />;
    return (
        <Row>
            <Col lg={12}>
                <div className="drager-3500">
                    <div className="aspect-ratio-container">
                        <div className="content-box">
                            <div className="content">
                                <svg viewBox="0 0 1080 1219" className="sensors-area" fill="none">
                                    {data.map((sensor, index) => {
                                        let separatorPositionY = (index + 1) * containerHeight,
                                            labelPositionY = index * containerHeight + (containerHeight / 2),
                                            labelPositionX = 40,
                                            unitPositionX = width - labelPositionX,
                                            containerPositionY = index * containerHeight;
                                        let readings;
                                        switch (sensor.alarm) {
                                            case 'a1': readings = <>
                                                <text
                                                    x="50%"
                                                    y={labelPositionY}
                                                    textAnchor="middle"
                                                    dominantBaseline="hanging"
                                                    className="sensor-alarm-level"
                                                >
                                                    A1
                                                </text>
                                            </>;
                                                break;
                                            case 'a2': readings = <>
                                                <text
                                                    x="50%"
                                                    y={labelPositionY}
                                                    textAnchor="middle"
                                                    dominantBaseline="hanging"
                                                    className="sensor-alarm-level"
                                                >
                                                    A2
                                                </text>
                                            </>;
                                                break;
                                            default: readings = <text
                                                x="50%"
                                                y={labelPositionY}
                                                textAnchor="middle"
                                                dominantBaseline="hanging"
                                                className="sensor-value"
                                            >
                                                {sensor.reading || "--"}
                                            </text>
                                        }
                                        return <g key={index} className={sensor?.alarm ? "alarm" : ""}>
                                            <rect x={0} y={containerPositionY} width={width} height={containerHeight} />
                                            <text
                                                x={labelPositionX}
                                                y={labelPositionY}
                                                textAnchor="start"
                                                dominantBaseline="middle"
                                                className="sensor-name"
                                            >
                                                {sensor?.sensorLabel || ""}
                                            </text>
                                            {readings}
                                            <text
                                                x={unitPositionX}
                                                y={labelPositionY}
                                                textAnchor="end"
                                                dominantBaseline="middle"
                                                className="sensor-unit"
                                            >
                                                {sensor?.units?.[0]?.name || sensor?.units?.[0]?.label || ""}
                                            </text>
                                            <line x1="0" y1={separatorPositionY} x2="100%" y2={separatorPositionY} stroke="#8CA5C2" strokeWidth="2" />
                                        </g>
                                    })}
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
}
export default DragerXAM3500Skin;