import { React, useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { messages } from "../utils/constants";

const ImportFilePopup = ({
    handleClose,
    headerName,
    show,
    isMulti = false,
    isLoading = false,
    accepts,
    message,
    actionLabel,
    onConfirm,
}) => {
    const [selectedFiles, setSelectedFiles] = useState();
    const onSelect = useCallback((e) => {
        setSelectedFiles(e.target.files);
    }, []);
    useEffect(() => {
        if (!show) setSelectedFiles(void 0);
    }, [show])
    return (
        <>
            <Modal show={show} onHide={isLoading ? () => null : handleClose}>
                <div
                    style={{
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                    }}
                >
                    <Modal.Header closeButton className="py-0  px-0 Heading-for-modal">
                        <p style={{ fontSize: "1.3rem" }}>
                            {headerName}
                        </p>
                    </Modal.Header>
                    <Modal.Body className="px-0 pb-0">
                        <div>
                            <div className="justify-content-center d-flex">
                                <p
                                    className="text-center"
                                    style={{ fontSize: "1.3rem", wordBreak: "break-word" }}
                                >
                                    {message}
                                </p>
                                <div className="w-full">
                                    <label htmlFor="file-upload">
                                        <input
                                            disabled={isLoading}
                                            className="hidden"
                                            type="file"
                                            name="file-upload"
                                            id="file-upload"
                                            multiple={isMulti}
                                            onChange={onSelect}
                                            accept={accepts}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="px-0">
                        <div className="submitAndCloseBtn mx-0">
                            <button
                                disabled={!selectedFiles?.length || isLoading}
                                className={`button-custom submitBtn forgot-password-button `}
                                variant="primary"
                                onClick={() => isLoading ? null : onConfirm?.(isMulti ? selectedFiles : selectedFiles[0])}
                            >
                                {actionLabel}
                            </button>
                            <button
                                className="button-custom closeBtn"
                                variant="primary"
                                onClick={handleClose}
                            >
                                {messages.cancel}
                            </button>
                        </div>

                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
};
export default ImportFilePopup;
